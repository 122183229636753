import { Button, Modal } from "antd";
import "./styles.scss";
import {
  RetweetOutlined,
  FileAddOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileJpgOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modalpayReducer } from "../../Redux/Reducers/Modal/modalpay";
import { TextField } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import headers from "../../config/headers";
import url from "../../url.json";
import axios from "axios";
import { modalapplicReducer } from "../../Redux/Reducers/Modal/modalapplic";
function ModalApplic(props) {
  const { t } = useTranslation();
  const show = useSelector((state) => state.modalapplic);
  const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState();
  const dispatch = useDispatch();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [names, setName] = useState();
  const [title, setTitle] = useState();
  const [amount, setamount] = useState();
  const openFile = () => {
    fileRef.current.click();
  };
  function handleChange(event) {
    if (event.target.files[0]?.name.split(".")[1] == "jpg")
      setIcon(<FileJpgOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "pdf")
      setIcon(<FilePdfOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "doc")
      setIcon(<FileWordOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "docx")
      setIcon(<FileWordOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "ppt")
      setIcon(<FilePptOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "pptx")
      setIcon(<FilePptOutlined />);
    setFile(event.target.files[0]);
  }
  useEffect(() => {
    if (props.file?.name.split(".")[1] == "jpg") setIcon(<FileJpgOutlined />);
    if (props.file?.name.split(".")[1] == "pdf") setIcon(<FilePdfOutlined />);
    if (props.file?.name.split(".")[1] == "doc") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "docx") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "ppt") setIcon(<FilePptOutlined />);
    if (props.file?.name.split(".")[1] == "pptx") setIcon(<FilePptOutlined />);
    setVisible(show.value);
    setFile(props.file);
    setTitle(props.file?.name.split(".")[0]);
  }, [show.value]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = () => {
    const data = new FormData();
    data.append("legal_file", file);
    setConfirmLoading(true);
    axios
      .post(url.url + `/contracts/${props.id}`, data, { headers: headers() })
      .then((res) => {
        if (res.status == 200) {
          dispatch(modalapplicReducer(false));
          setVisible(false);
          setConfirmLoading(false);
          toast.success(t("Success!"));
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        toast.error(err.response.data.message);
      });
  };

  const handleCancel = () => {
    dispatch(modalapplicReducer(false));
    setVisible(false);
  };
  return (
    <>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Modal
        title={t("Pay ")}
        visible={visible}
        onOk={handleOk}
        okText={t("Ok")}
        cancelText={t("Cancel")}
        confirmLoading={confirmLoading}
        onCancel={() => {
          dispatch(modalapplicReducer(false));
          setVisible(false);
          props.handleCancel();
        }}
      >
        <div className="mod-container">
          <div className="mod-inner">
            <div className="mod-inner-holder">
              <div className="mod-icon">{icon}</div>
              <div className="mod-file">
                <h3 className="mod-file-name">{file?.name}</h3>
                {file ? (
                  <span className="mod-file-info">
                    {Math.ceil(file?.size / 1024)} kb
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mod-inner-icon">
              <RetweetOutlined className="mod-res" onClick={openFile} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(ModalApplic);
