import React, { useEffect, useState } from "react";
import "../Landing.scss";
import Location from "../../../assets/icon/location.svg";
import Schedule from "../../../assets/icon/schedule.svg";
import Phone from "../../../assets/icon/phone.svg";
import Telegram from "../../../assets/icon/telegram.svg";
import Facebook from "../../../assets/icon/facebook.svg";
import Instagram from "../../../assets/icon/instagram.svg";
import Logo from "../../../assets/icon/image.png";
import { withTranslation } from "react-i18next";
import axios from "axios";
import url from "../../../url.json";
import headers from "../../../config/headers";
import { useSelector, useDispatch } from "react-redux";
import { aboutReducer } from "../../../Redux/Reducers/About/about";
function LandingFoot({ t }) {
  const [about, setAbout] = useState();
  const [contact, setcontact] = useState();
  const lan = useSelector((state) => state.langReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    axios.get(url.url + "/about", { headers: headers() }).then((res) => {
      setAbout(res.data.data);
      dispatch(aboutReducer(res.data.data));
    });
    axios.get(url.url + "/contact", { headers: headers() }).then((res) => {
      setcontact(res.data.data);
    });
  }, [lan]);
  const [phones, setPhones] = useState();
  useEffect(() => {
    for (let i in contact) {
      if (contact[i]?.type == "phone") {
        setPhones(contact[i]);
      }
    }
  }, [contact]);

  return (
    <div className="landing-footer">
      <div className="landing-footer__wrapper landing-container">
        <div className="landing-footer__holderr">
          <img
            className="landing-footer__holder-icon"
            src={about?.logo}
            alt="site logo"
            width={60}
            height={60}
          />
          <span className="landing-footer__holder-text">Vis nefrit</span>
          <div>
            <p className="ttt">{t("footer_info")}</p>
          </div>
        </div>
        <div className="landing-footer__holder">
          <ul className="landing-footer__holder-list">
            <li className="landing-footer__holder-item">
              <img
                className="landing-footer__holder-img"
                src={Location}
                alt="location icon"
                width={20}
                height={20}
              />
              <p className="landing-footer__holder-desc">{about?.address}</p>
            </li>
            <li className="landing-footer__holder-item">
              <a
                className="landing-footer__holder-link"
                href={`tel:${phones?.value}`}
              >
                <img
                  className="landing-footer__holder-img"
                  src={Phone}
                  alt="phone icon"
                  width={20}
                  height={20}
                />
                <span className="landing-footer__holder-desc phone-link">
                  {phones?.value}
                </span>
              </a>
            </li>
            <li className="landing-footer__holder-item">
              <img
                className="landing-footer__holder-img"
                src={Schedule}
                alt="schedule icon"
                width={20}
                height={20}
              />
              <div className="landing-footer__holder-wrapper">
                <p className="landing-footer__holder-desc">
                  <span className="landing-footer__holder-day">
                    {t("Mon-Sat")}
                  </span>
                  <span className="landing-footer__holder-time">
                    9:00 - 18:00
                  </span>
                </p>

              </div>
            </li>
          </ul>
        </div>
        <div className="landing-footer__holder landing-footer__holder-links">
          {/* <h3 className="landing-footer__holder-title">links</h3> */}
          <ul className="landing-footer__holder-list">
            {/* <li className="landing-footer__holder-item">
              <a className="landing-footer__holder-link" href="#">
                {t("Home")}
              </a>
            </li> */}
            <li className="landing-footer__holder-item">
              <a className="landing-footer__holder-link" href="#about">
                {t("About")}
              </a>
            </li>
            <li className="landing-footer__holder-item">
              <a className="landing-footer__holder-link" href="#blog">
                {t("News")}
              </a>
            </li>
            <li className="landing-footer__holder-item">
              <a className="landing-footer__holder-link" href="#contact">
                {t("Contact")}
              </a>
            </li>
            <li className="landing-footer__holder-item">
              <a className="landing-footer__holder-link" href="/regulations">
                {t("Regulations")}
              </a>
            </li>
            <li className="landing-footer__holder-item">
              <a className="landing-footer__holder-link" href="/reestr">
                {t("Reestr")}
              </a>
            </li>
            <li className="landing-footer__holder-item">
              <a className="landing-footer__holder-link" href="/faq">
                {t("FAQ")}
              </a>
            </li>
          </ul>
        </div>

        <div className="landing-footer__holder landing-socials__holder">
          {/* <h3 className="landing-footer__holder-title">Socials</h3> */}
          <ul className="landing-footer__holder-social socials">
            {contact?.map((res, i) =>
              res.type == "phone" ? (
                ""
              ) : (
                <li key={i} className="socials__item">
                  <a className="socials__link" href={res.value} target="_blank">
                    <img src={res.icon} width={25} height={25} />
                    <p className="socials__tt">{res.name}</p>
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(LandingFoot);
