import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Hero from "../Hero.js";
import "../Landing.scss";
import BlogSlider from "../../../components/BlogSlider/BlogSlider";
import BlogCard from "../../../components/BlogCard/BlogCard.js";
import axios from "axios";
import url from "../../../url.json";
import headers from "../../../config/headers.js";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import YandexMap from "../../../components/Map/Map.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Youtube from "../../../components/Youtube/Youtube.jsx";
import { getprofilAsync } from "../../../Redux/Reducers/Profil/profilSlice.js";

const clamp = (value) => Math.max(0, value);

const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;

const useScrollspy = (ids, offset) => {
  const [activeId, setActiveId] = useState("");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};
function HomeLand({ t }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [about, setAbout] = useState();
  const [article, setArticle] = useState();
  const lan = useSelector((state) => state.langReducer);
  const namref = useRef(null);
  const emailref = useRef(null);
  const messageref = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (searchParams.get("code")) {
      const data = {
        code: searchParams.get("code"),
      };
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      axios
        .post(url.url + "/eds", data, { headers: headers() })
        .then((res) => {
          toast.success(res.data.data.message);
          dispatch(getprofilAsync("legal-entity/profile"));
          navigate("/account/applications");
        })
        .catch((err) => {
          toast.error(err.response.data.error.message);

          navigate("/register/last-step");
        });
    }
  }, [searchParams.get("code")]);
  useEffect(() => {
    axios.get(url.url + "/about", { headers: headers() }).then((res) => {
      setAbout(res.data.data);
    });
    axios.get(url.url + "/article", { headers: headers() }).then((res) => {
      setArticle(res.data);
    });
  }, [lan.value]);
  const [fullname, setFullname] = useState();
  const [email, setemail] = useState();
  const [message, setmessage] = useState();
  const [error, seterror] = useState();
  const handeSave = () => {
    const data = {
      full_name: namref.current.value,
      email: emailref.current.value,
      message: messageref.current.value,
    };

    axios
      .post(url.url + "/contactmessage", data, { headers: headers() })
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.data.message);
          document.getElementById("form_contact").reset();
          seterror();
          setFullname();
          setemail();
          setmessage();
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        seterror(err.response.data.errors);
      });
  };

  const ids = ["home", "about", "blog", "contact"];
  const activeId = useScrollspy(ids, 54);
  const youtube = [
    { title: t("Title1"), url: "https://www.youtube.com/embed/HXyKRDB3y5g" },
    { title: t("Title2"), url: "https://www.youtube.com/embed/OtyRTmHT0jY" },
    { title: t("Title3"), url: "https://www.youtube.com/embed/YlYVlbga_Lk" },
    { title: t("Title4"), url: "https://www.youtube.com/embed/fSdu3eEZ6Ak" },
    { title: t("Title5"), url: "https://www.youtube.com/embed/Cpx48ZLUfTc" },
    { title: t("Title6"), url: "https://www.youtube.com/embed/nLk7RlFJ0cc" },
  ];
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vis Nefrit | {t("Home")}</title>
          <meta name="description" content={t("herotitle")} />
        </Helmet>
        <div className="landing-wrapper">
          <Hero />
        </div>
        <div className="landing-blog">
          <div className="landing-blog__inner landing-container">
            <h2 className="landing__heading">{t("Video guide")}</h2>

            <div className="landing-blog__wrapper">
              <BlogSlider>
                {youtube.map((res, i) => (
                  <Youtube data={res} key={i} />
                ))}
              </BlogSlider>
            </div>
          </div>
        </div>

        <div className="landing-about" id="about">
          <div className="landing-container">
            <h2 className="landing__heading">{t("About")}</h2>
            <div className="landing-about__wrapper">
              <div className="landing-about__left">
                <h3
                  className="landing-about__title "
                  style={{ fontSize: 26, whiteSpace: "pre" }}
                >
                  {about?.name}
                </h3>
                <div
                  className="landing-about__text"
                  dangerouslySetInnerHTML={{ __html: about?.description }}
                ></div>
              </div>

              <div className="landing-about__right">
                {about?.logo ? (
                  <img src={about?.logo} width={280} height={280} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {article?.data.length ? (
          <div className="landing-blog" id="blog">
            <div className="landing-blog__inner landing-container">
              <h2 className="landing__heading">{t("latest blog")}</h2>

              <div className="landing-blog__wrapper">
                <BlogSlider>
                  {article?.data.map((res, i) => (
                    <BlogCard data={res} key={i} />
                  ))}
                </BlogSlider>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="landing-contact" id="contact">
          <div className="landing-contact__wrapper landing-container">
            <h2 className="landing__heading">{t("Contact us")}</h2>

            <div className="landing-contact__holder">
              <form
                className="landing-contact__form contact-form"
                id="form_contact"
              >
                <div className="contact-form__holder">
                  <label className="contact-form__label" htmlFor="name">
                    {t("fullname")}
                  </label>
                  <input
                    name="full_name"
                    className="contact-form__inp "
                    type="text"
                    ref={namref}
                    onChange={(e) => setFullname(e.target.value)}
                    placeholder={t("fullname")}
                  />
                </div>
                {error?.full_name ? (
                  <i style={{ color: "red", textAlign: "left" }}>
                    {error?.full_name}
                  </i>
                ) : (
                  ""
                )}
                <div className="contact-form__holder">
                  <label className="contact-form__label" htmlFor="email">
                    {t("email")}
                  </label>
                  <input
                    name="email"
                    className="contact-form__inp"
                    type="email"
                    ref={emailref}
                    onChange={(e) => setemail(e.target.value)}
                    placeholder={t("email")}
                  />
                </div>
                {error?.email ? (
                  <i style={{ color: "red", textAlign: "left" }}>
                    {error?.email}
                  </i>
                ) : (
                  ""
                )}

                <div className="contact-form__holder">
                  <label className="contact-form__label" htmlFor="textarea">
                    {t("message")}
                  </label>
                  <textarea
                    name="message"
                    ref={messageref}
                    onChange={(e) => setmessage(e.target.value)}
                    className="contact-form__textarea"
                    placeholder={t("write your message here")}
                  ></textarea>
                </div>
                {error?.message ? (
                  <i style={{ color: "red", textAlign: "left" }}>
                    {error?.message}
                  </i>
                ) : (
                  ""
                )}

                <div className="contact-form__holder btn-holder">
                  <button
                    className="contact-form__btn"
                    type="button"
                    onClick={handeSave}
                  >
                    {t("submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="iframe__holder"
        style={{ width: "100%", height: "320px" }}
      >
        <YandexMap point_x={about?.point_x} point_y={about?.point_y} />
      </div>
    </>
  );
}
export default withTranslation()(HomeLand);
