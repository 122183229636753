import React, { useEffect, useState, useCallback, useRef } from "react";
import "../settings/settings.scss";
import { Button, Checkbox, Form, Input, Select } from "antd";
import url from "../../url.json";
import headers from "../../config/headers";
import Edit from "../../assets/icon/delete.svg";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Skeleton } from "antd";
import { userReducer } from "../../Redux/Reducers/User/user";
import { FormOutlined, CloseOutlined } from "@ant-design/icons";
import { getprofilAsync } from "../../Redux/Reducers/Profil/profilSlice";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-number-input";
const { Option } = Select;

function Profile({ t }) {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [avater, setavatar] = useState();
  const [status, setStatus] = useState(false);
  const [change, setChange] = useState();
  const [datas, setDatas] = useState();
  const [avatar, setavt] = useState();
  const [deleted, setDeleted] = useState(false);
  const [country, setCountry] = useState();
  const [valuePhone, setValuePhone] = useState();

  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + "/legal-entity/profile", { headers: headers() })
      .then((res) => {
        setDatas(res.data.data);
        setavatar(res.data.data.avatar);
        setChange(res.data.data.legal_entity?.is_national);
        setValuePhone(res.data.data?.legal_entity?.phone);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
    axios
      .get(url.url + "/country", { headers: headers() })
      .then((res) => {
        setCountry(res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        toast.error(err.response.data);
      });
  }, []);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setavt(fileUploaded);
    setDeleted(false);
    setavatar(URL.createObjectURL(fileUploaded));
  };

  const [error, setError] = useState();

  const onFinish = (values) => {
    var form_data = new FormData();
    if (deleted) form_data.append("avatar_deleted", deleted);
    console.log(values);
    for (var key in values) {
      form_data.append(key, values[key]);
    }
    form_data.append("verified_at", dateFormat(new Date(), "yyyy-mm-dd"));
    form_data.append("avatar", avatar);
    form_data.append(
      "country_id",
      document.getElementById("examplecountry").value
    );
    form_data.append("phone", valuePhone);

    if (values.is_national) {
      form_data.append("is_national", 1);
    } else form_data.append("is_national", 0);
    axios
      .post(url.url + "/legal-entity/update", form_data, { headers: headers() })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.data));
        dispatch(userReducer(res.data.data));

        if (res.status == 200) {
          dispatch(getprofilAsync("legal-entity/profile"));
          toast.success(t("Success!"));
          setError();
          setStatus(!status);
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setError(err.response.data.errors);
      });
  };
  const handleshow = () => {
    if (avater) {
      setavatar();
      setDeleted(true);
      setavt();
    } else handleClick();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  if (datas)
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vis Nefrit | {t("Profil")}</title>
        </Helmet>
        <div className="settings__info info-settings">
          <div className="modal__div">
            <label
              className="modal__images"
              htmlFor="modalImg"
              onClick={handleClick}
            >
              <input
                className="modal-file__inp"
                type="file"
                name="modalImg"
                ref={hiddenFileInput}
                onChange={handleChange}
              />
              {avater ? (
                <img src={avater} className="modal__images" />
              ) : (
                <span className="modal__images-tt">{t("img")}</span>
              )}
            </label>
            <button
              className="edit-avatar"
              type="button"
              onClick={() => handleshow()}
            >
              {avater || avatar ? (
                <img src={Edit} alt="edit icon" />
              ) : (
                <FormOutlined />
              )}
            </button>
          </div>
          <div className="ml-0 ml-sm-3 ml-md-3 w-100">
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                username: datas?.username,
                email: datas?.email,
                name: datas?.legal_entity?.name,
                phone: datas?.legal_entity?.phone,
                inn: datas?.legal_entity?.inn,
                address: datas?.legal_entity?.address,
                boss_name: datas?.legal_entity?.boss_name,
                fax: datas?.legal_entity?.fax,
                website: datas?.legal_entity?.website,
                is_national:
                  datas?.legal_entity?.is_national == 1 ? true : false,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row justify-content-between ">
                <div className="col-md-4">
                  <Form.Item label={t("Username")} name="username">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.username ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.username}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <Form.Item label={t("Email")} name="email">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.email ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.email}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <Form.Item label={t("Name")} name="name">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.name ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.name}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-between mt-3 ">
                <div className="col-md-4">
                  {/* <Form.Item label={t("Phone")} name="phone">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.phone ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.phone}
                    </span>
                  ) : (
                    ""
                  )} */}
                  <Form.Item label={t("Phone")} name="phone">
                    <PhoneInput
                      style={{ display: "flex" }}
                      className={"ant-input inppp"}
                      international
                      defaultCountry="UZ"
                      value={valuePhone}
                      onChange={(e) => setValuePhone(e)}
                    />
                    {error?.phone ? (
                      <span style={{ fontStyle: "italic", color: "red" }}>
                        {error?.phone}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item label={t("Address")} name="address">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.address ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.address}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <Form.Item label={t("Inn")} name="inn">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.inn ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.inn}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-between mt-3">
                <div className="col-md-4">
                  <Form.Item label={t("Boss name")} name="boss_name">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.boss_name ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.boss_name}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <div className="ant-form-item-labelss">{t("Country")}</div>

                  <select
                    id="examplecountry"
                    className="inpps-select"
                    defaultValue={`${datas?.legal_entity?.country.id}`}
                  >
                    <option value={datas?.legal_entity?.country?.id}>
                      {datas?.legal_entity?.country?.name}
                    </option>
                    {country?.data.map((res, i) => (
                      <option key={i} value={res.id}>
                        {res.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <Form.Item label={t("Fax address")} name="fax">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.fax ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.fax}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row  mt-3">
                <div className="col-md-4">
                  <Form.Item label={t("Website")} name="website">
                    <Input className="inppp" />
                  </Form.Item>
                  {error?.website ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.website}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="col-md-4  mt-4">
                  <Form.Item name="is_national" valuePropName="checked">
                    <Checkbox>{t("Is National")}</Checkbox>
                  </Form.Item>
                  {error?.is_national ? (
                    <span style={{ fontStyle: "italic", color: "red" }}>
                      {error?.is_national}
                    </span>
                  ) : (
                    ""
                  )}
                </div> */}
              </div>

              <Form.Item>
                <div
                  className="justify-content-end"
                  style={{ display: "flex" }}
                >
                  <Button type="primary" htmlType="submit">
                    {t("Save")}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  else {
    return (
      <div className="settings__info info-settings">
        <Skeleton
          avatar
          paragraph={{
            rows: 4,
          }}
        />
      </div>
    );
  }
}
export default withTranslation()(Profile);
