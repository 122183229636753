import { Button, Modal } from "antd";
import {
  RetweetOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileJpgOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modaldocReducer } from "../../Redux/Reducers/Modal/modaldoc";
import { withTranslation, useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import axios from "axios";
import headers from "../../config/headers";
import url from "../../url.json";
import { ToastContainer, toast } from "react-toastify";

function ModalDoc(props) {
  const { t } = useTranslation();
  const show = useSelector((state) => state.modaldoc);
  const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState();
  const dispatch = useDispatch();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [title, setTitle] = useState();
  const [names, setName] = useState();
  const openFile = () => {
    fileRef.current.click();
  };
  function handleChange(event) {
    if (event.target.files[0]) {
      if (event.target.files[0]?.name.split(".")[1] == "jpg")
        setIcon(<FileJpgOutlined />);
      if (event.target.files[0]?.name.split(".")[1] == "pdf")
        setIcon(<FilePdfOutlined />);
      if (event.target.files[0]?.name.split(".")[1] == "doc")
        setIcon(<FileWordOutlined />);
      if (event.target.files[0]?.name.split(".")[1] == "docx")
        setIcon(<FileWordOutlined />);
      if (event.target.files[0]?.name.split(".")[1] == "ppt")
        setIcon(<FilePptOutlined />);
      if (event.target.files[0]?.name.split(".")[1] == "pptx")
        setIcon(<FilePptOutlined />);
      setFile(event.target.files[0]);
      setTitle(event.target.files[0].name.split(".")[0]);
    } else {
      setFile();
      setTitle();
    }
  }
  useEffect(() => {
    if (props.file?.name.split(".")[1] == "jpg") setIcon(<FileJpgOutlined />);
    if (props.file?.name.split(".")[1] == "pdf") setIcon(<FilePdfOutlined />);
    if (props.file?.name.split(".")[1] == "doc") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "docx") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "ppt") setIcon(<FilePptOutlined />);
    if (props.file?.name.split(".")[1] == "pptx") setIcon(<FilePptOutlined />);
    setVisible(show.value);
    setFile(props.file);
    setTitle(props.file?.name.split(".")[0]);
  }, [show.value]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  const handleOk = () => {
    const data = new FormData();
    data.append("category_id", props.categoryid);
    data.append("application_id", localStorage.getItem("applic"));
    data.append("name", names ? names : title);
    data.append("file", file);
    setConfirmLoading(true);
    axios
      .post(url.url + "/document", data, { headers: headers() })
      .then((res) => {
        if (res.status == 201) {
          dispatch(modaldocReducer(false));
          setName();
          setVisible(false);
          setConfirmLoading(false);
          toast.success(t("Success!"));
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        toast.error(err.response.data.errors);
      });
  };

  const handleCancel = () => {
    dispatch(modaldocReducer(false));
    setVisible(false);
    setFile();
  };

  return (
    <>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Modal
        title={props.title}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          props.handleCancel();
        }}
        okText={t("Ok")}
        cancelText={t("Cancel")}
      >
        <div className="mod-container">
          <div className="mod-inner">
            <div className="mod-inner-holder">
              <div className="mod-icon">{icon}</div>
              <div className="mod-file">
                {file ? (
                  <div>
                    <h3 className="mod-file-title">{file?.name}</h3>

                    <span className="mod-file-name">
                      {Math.ceil(file?.size / 1024)} kb
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mod-inner-icon">
              <RetweetOutlined onClick={openFile} />
            </div>
          </div>
          <div key={title}>
            <TextField
              className="text-field"
              fullWidth
              id="standard-basic"
              variant="standard"
              type="text"
              defaultValue={title}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(ModalDoc);
