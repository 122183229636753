import React, { useEffect, useState } from "react";
import "./List.scss";
import url from "../../url.json";
import { toast } from "react-toastify";
import { Table } from "reactstrap";
import Delete from "../../assets/icon/delete.svg";
import Eye from "../../assets/icon/eye.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import headers from "../../config/headers";
import dateFormat from "dateformat";
import { Empty } from "antd";
import "antd/dist/antd.css";
import { Button, Dropdown, Menu } from "antd";
import Dowolland from "../Icons/Dowolland";
import { Spin, Pagination } from "antd";
import ModalDelete from "../Modal/ModalDelete";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

function List({ t }) {
  const [shows, setShows] = useState(false);
  const [datares, setDatares] = useState();
  const handleCancel = () => {
    setShows(false);
  };
  const handleshow = (res) => {
    localStorage.setItem("applic", res.id);
    if (res.contract_id == null)
      navigate({
        pathname: "/account/applications/show",
        search: `?${createSearchParams({
          id: res.id,
        })}`,
      });
    else
      navigate({
        pathname: "/account/applications/show",
        search: `?${createSearchParams({
          id: res.id,
          con_id: res.contract_id,
        })}`,
      });
  };
  const handleEdit = (res) => {
    localStorage.setItem("applic", res.id);
    if (res.contract_id === null)
      navigate({
        pathname: "/account/applications/edit",
        search: `?${createSearchParams({
          id: res.id,
        })}`,
      });
    else
      navigate({
        pathname: "/account/applications/edit",
        search: `?${createSearchParams({
          id: res.id,
          con_id: res.contract_id,
        })}`,
      });
  };

  const menu = (res) => {
    return (
      <Menu
        items={[
          {
            label: t("Show"),
            key: "1",
            icon: <img className="btn-icon" src={Eye} alt="eye icon" />,
            onClick: () => handleshow(res),
          },
          {
            label: t("Edit"),
            key: "2",
            icon: <ModeEditOutlinedIcon width={18} height={18} />,
            onClick: () => handleEdit(res),
          },
          {
            label: t("Delete"),
            key: "3",
            icon: <img className="btn-icon" src={Delete} alt="delete icon" />,
            onClick: () => {
              setShows(true);
              setDatares(res);
            },
          },

          {
            label: t("Dowolland"),
            key: "4",
            icon: <Dowolland width={18} height={18} />,
            onClick: () => (window.location.href = res.application),
          },
        ]}
      />
    );
  };
  const navigate = useNavigate();
  const [datas, setData] = useState();
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + "/application", { headers: headers() })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [localStorage.getItem("lan"), shows]);
  const handlePage = (e) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + `/application?page=${e}`, { headers: headers() })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  return (
    <>
      <ModalDelete show={shows} handleCancel={handleCancel} res={datares} />
      {datas ? (
        <div className="resp table-responsive">
          {datas?.data.result.length > 0 ? (
            <div>
              <Table
                className="tttt"
                responsive="sm"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th className="thh">#</th>
                    <th className="thh">{t("Code")}</th>
                    <th className="thh">{t("Applicant")}</th>
                    <th className="thh">{t("Drug name")}</th>
                    <th className="thh">{t("Patent")}</th>
                    <th className="thh">{t("Stage")}</th>
                    <th className="thh">{t("Status")}</th>
                    <th className="thh thh-btn">{t("Sertificat")}</th>
                    <th className="thh thh-date">{t("Date")}</th>
                    <th className="thh">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {datas?.data.result.map((res, i) => (
                    <tr key={i}>
                      <td className="tdd">
                        {datas?.data.pagination.per_page *
                          (datas?.data.pagination.current_page - 1) +
                          i +
                          1}
                      </td>
                      <td className="tdd">{res.code}</td>
                      <td className="tdd">
                        <p className="tdd-app">{res?.applicant}</p>
                      </td>
                      <td className="tdd tdd-app">{res?.drug.name}</td>
                      <td className="tdd">{res.drug.patent}</td>
                      <td className="tdd">
                        <div className="tss">{res.stage.name}</div>
                      </td>
                      <td className="tdd">
                        <div
                          className={`btn out btn-outline-${
                            res.status.color == "orange"
                              ? "warning"
                              : res.status.color
                          } `}
                        >
                          {res.status.name}
                        </div>
                      </td>
                      <td className="tdd tdd-btn">
                        {res.certificate || res?.cancel || res?.return ? (
                          <Button
                            className="btnnn"
                            type="primary"
                            shape="round"
                            icon={<FileDownloadOutlinedIcon />}
                            size={12}
                            onClick={() =>
                              res.certificate
                                ? (window.location.href = res.certificate?.file)
                                : res?.cancel
                                ? (window.location.href = res.cancel?.file)
                                : (window.location.href = res.return?.file)
                            }
                          >
                            {t("Download")}
                          </Button>
                        ) : (
                          <Button
                            className="btnnn"
                            type="primary"
                            shape="round"
                            disabled
                            icon={<FileDownloadOutlinedIcon />}
                            size={12}
                          >
                            {t("Download")}
                          </Button>
                        )}
                      </td>
                      <td className="tdd tdd-date">
                        {res?.date
                          ? dateFormat(res?.date, "dd.mm.yyyy")
                          : "__.__.____"}
                      </td>
                      <td className="tdd">
                        <Dropdown
                          overlay={menu(res)}
                          placement="bottomRight"
                          trigger={["click"]}
                        >
                          <i className="header-list__icon bx bx-dots-vertical-rounded"></i>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="pagin">
                <Pagination
                  pageSize={datas.data.pagination.per_page}
                  style={{ borderRadius: 15 }}
                  onChange={(e) => handlePage(e)}
                  defaultCurrent={1}
                  total={datas.data.pagination.total}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 500,
                backgroundColor: "white",
                borderRadius: 12,
              }}
            >
              <Empty description={t("No data")} />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 500,
            backgroundColor: "white",
            borderRadius: 12,
          }}
        >
          <Spin tip={t("Loading...")}>
            <Empty description={t("No data")} />
          </Spin>
        </div>
      )}
    </>
  );
}

export default withTranslation()(List);
