import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { withTranslation } from "react-i18next";
import { Pagination, Empty } from "antd";
import MainHeader from "../../components/MainHeader/MainHeader.js";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import "./styles.scss";
import dateFormat from "dateformat";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
function Notification({ t }) {
  const [datas, setData] = useState();
  const [newdatas, setNewData] = useState({});
  const navigate = useNavigate();
  const lan = useSelector((state) => state.langReducer);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios
      .get(url.url + `/notice?search=read`, {
        headers: headers(),
      })
      .then((res) => {
        setNewData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
    axios
      .get(url.url + `/notice`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [lan]);
  const handlePage = (e) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + `/notice?page=${e}`, { headers: headers() })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  const handleOpen = (id) => {
    navigate({
      pathname: "/account/notic-open",
      search: `?${createSearchParams({
        id: id,
      })}`,
    });
  };
  return (
    <div>
      <MainHeader title={t("Notifications list")}></MainHeader>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Notifications list")}</title>
      </Helmet>
      {datas?.result.length > 0 ? (
        <div>
          {datas?.result?.map((res, i) => (
            <div
              className={res.read_at ? "not-card" : "not-card"}
              key={i}
              onClick={() => handleOpen(res.id)}
            >
              <Card
                bordered={false}
                style={{
                  borderRadius: 15,
                  width: "100%",
                }}
              >
                <div className={!res.read_at ? "wrr" : ""}>
                  <h2 className="not-title" href="#">
                    {res.message}
                  </h2>
                  <p className="not-desc">{res.department}</p>
                </div>
                <div className="date-wr">
                  <p className="not-text">{res.text}</p>
                  <span className="not-date">
                    {dateFormat(res.created_at, "dd.mm.yyyy")}
                  </span>
                </div>
              </Card>
            </div>
          ))}
          <div className="pagin">
            <Pagination
              style={{ borderRadius: 15 }}
              onChange={(e) => handlePage(e)}
              current={datas?.pagination.current_page}
              total={datas?.pagination.total}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 500,
            backgroundColor: "white",
            borderRadius: 12,
          }}
        >
          <Empty description={t("No data")} />
        </div>
      )}
    </div>
  );
}
export default withTranslation()(Notification);
