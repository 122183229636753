import React, { useEffect, useState } from "react";
import "./contract.scss";
import url from "../../url.json";
import { useDispatch, useSelector } from "react-redux";
import MainHeader from "../../components/MainHeader/MainHeader.js";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  UploadOutlined,
  CreditCardOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import axios from "axios";
import headers from "../../config/headers";
import { Empty } from "antd";
import "antd/dist/antd.css";
import { Button, Menu } from "antd";
import Edit from "../../components/Icons/Edit";
import Dowolland from "../../components/Icons/Dowolland";
import Upload from "../../assets/icon/upload.svg";
import { Alert, Spin } from "antd";
import { Table } from "reactstrap";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ModalApplic from "../../components/Modal/ModalApplic";
import { modalapplicReducer } from "../../Redux/Reducers/Modal/modalapplic";
import { Helmet } from "react-helmet-async";
function Cantract({ t }) {
  const navigate = useNavigate();
  const [datas, setData] = useState();
  const [dataId, setDataId] = useState();
  const lan = useSelector((state) => state.langReducer);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios
      .get(url.url + `/contracts`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [lan.value]);
  function handleShow(id) {
    axios
      .get(url.url + `/application/${id}`, {
        headers: headers(),
      })
      .then((res) => {
        localStorage.setItem("applic", res.data.data.id);
        if (res.contract_id == null)
          navigate({
            pathname: "/account/applications/show",
            search: `?${createSearchParams({
              id: res.data.data.id,
            })}`,
          });
        else
          navigate({
            pathname: "/account/applications/show",
            search: `?${createSearchParams({
              id: res.data.data.id,
              con_id: res.data.data.contract_id,
            })}`,
          });
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }
  const dispatch = useDispatch();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const showModal = () => {
    dispatch(modalapplicReducer(true));
  };
  const openFile = (a) => {
    setDataId(a);
    fileRef.current.click();
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  const menu = (res) => {
    return (
      <Menu
        items={[
          {
            label: t("Upload"),
            key: "1",
            icon: <UploadOutlined width={18} height={18} />,
            onClick: () => openFile(res.id),
          },
          {
            label: t("Dowolland"),
            key: "2",
            icon: <Dowolland width={18} height={18} />,
            onClick: () => {
              window.location.href = res?.file;
            },
          },
          {
            label: t("Payments"),
            key: "3",
            icon: <CreditCardOutlined width={18} height={18} />,
            onClick: () =>
              navigate({
                pathname: "/account/contract/paylist",
                search: `?${createSearchParams({
                  id: res.id,
                })}`,
              }),
          },
        ]}
      />
    );
  };
  if (datas)
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vis Nefrit | {t("Contracts list")}</title>
        </Helmet>
        <MainHeader title={t("Contracts list")}></MainHeader>
        <ModalApplic file={file} id={dataId} />
        <input
          type="file"
          id="file"
          ref={fileRef}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        {datas.length > 0 ? (
          <div className="resp table-responsive">
            <Table responsive="sm" style={{ backgroundColor: "white" }}>
              <thead className="thead">
                <tr className="thead">
                  <th className="thhh">#</th>
                  <th className="thhh">{t("Contract name")}</th>
                  <th className="thhh">{t("Applications")}</th>
                  <th className="thhh">{t("Amount")}</th>
                  <th className="thhh">{t("Status")}</th>
                  <th className="thhh">{t("Template")}</th>
                  <th className="thhh">{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {datas?.map((res, i) => (
                  <tr key={i}>
                    <td className="tddd">{i + 1}</td>
                    <td className="tddd">
                      {res.file ? (
                        <a
                          href={res.file}
                          target="_blank"
                          style={{ color: "#109cf1", textDecoration: "none" }}
                        >
                          {res.name}
                        </a>
                      ) : (
                        <span
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          {res.name}
                        </span>
                      )}
                    </td>
                    <td className="tddd">
                      <div className="list_td">
                        {res.applications?.map((data, i) => (
                          <div
                            key={i}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShow(data.id)}
                          >
                            {data?.code}
                            <br />
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="tddd">{res.payment_amount}</td>

                    <td className="tddd">
                      <div
                        className={
                          res.is_paid == 1
                            ? `btn outt btn-outline-primary`
                            : `btn outt btn-outline-secondary`
                        }
                      >
                        <p className="outt-tt">
                          {res.is_paid == 1 ? t("paid") : t("unpaid")}
                        </p>
                      </div>
                    </td>
                    <td className="tddd">
                      {res.template_file ? (
                        <Button
                          className="btnnn"
                          type="primary"
                          shape="round"
                          icon={<FileDownloadOutlinedIcon />}
                          size={12}
                          onClick={() =>
                            (window.location.href = res.template_file)
                          }
                        >
                          {t("Download")}
                        </Button>
                      ) : (
                        <Button
                          className="btnnn"
                          type="primary"
                          shape="round"
                          disabled
                          icon={<FileDownloadOutlinedIcon />}
                          size={12}
                        >
                          {t("Download")}
                        </Button>
                      )}
                    </td>

                    <td className="tddd">
                      <EyeOutlined
                        width={18}
                        height={18}
                        onClick={() =>
                          navigate({
                            pathname: "/account/contract/paylist",
                            search: `?${createSearchParams({
                              id: res.id,
                            })}`,
                          })
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 500,
              backgroundColor: "white",
              borderRadius: 15,
            }}
          >
            <Empty description={t("No data")} />
          </div>
        )}
      </>
    );
  else
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 500,
          backgroundColor: "white",
          borderRadius: 12,
        }}
      >
        <Spin tip={t("Loading...")}>
          <Empty description={t("No data")} />
        </Spin>
      </div>
    );
}
export default withTranslation()(Cantract);
