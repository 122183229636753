import React, { useEffect, useState } from "react";
import "./blog.scss";
import user from "../../../assets/icon/userblog.svg";
import blogCal from "../../../assets/icon/blogCal.svg";
import dateFormat from "dateformat";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import axios from "axios";
import url from "../../../url.json";
import headers from "../../../config/headers.js";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
function BlogOpen({ t }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [article, setArticle] = useState();
  const lan = useSelector((state) => state.langReducer);

  useEffect(() => {
    axios
      .get(url.url + `/article/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setArticle(res.data.data);
      });
  }, [lan.value]);

  return (
    <>
      <div className="card-open">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vis Nefrit | {t("Blog")}</title>
        </Helmet>
        <div className="landing-container">
          <div className="card-open__wrapper">
            <div className="card-open__wrapper-head card-head">
              <img
                className="card-head__img"
                src={article?.image}
                alt="card opened img"
                width="600"
                height="400"
              />
            </div>
            <div className="card-open__wrapper-body card-body">
              <ul className="card-body__list">
                <li className="card-body__item">
                  <img
                    className="card-body__item-img"
                    src={user}
                    width={14}
                    height={14}
                  />
                  <span className="card-body__item-tt">
                    {t("Muallif")}: {article?.author}
                  </span>
                </li>

                <li className="card-body__item">
                  <img
                    className="card-body__item-img"
                    src={blogCal}
                    width={14}
                    height={14}
                  />
                  <span className="card-body__item-tt">
                    {dateFormat(article?.date, "dd.mm.yyyy")}
                  </span>
                </li>
              </ul>
              <h3 className="card-body__title">{article?.title}</h3>
              <div
                className="card-body__text"
                dangerouslySetInnerHTML={{ __html: article?.text }}
              />

              {/* <p className="card-body__text">{article?.text}</p> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="carddd-open ">
        <div className="card-open landing-container">
          <div className="card-wrapper__holder">
            <img className="card-wrapper__img " src={article?.image} />
          </div>
          <div className="card-wrapper__body">
            <div className="card-detail__info">
              <ul className="card-body__list">
                <li className="card-body__item">
                  <img
                    className="card-body__item-img"
                    src={user}
                    width={14}
                    height={14}
                  />
                  <span className="card-body__item-tt">
                    {t("Muallif")}: {article?.author}
                  </span>
                </li>

                <li className="card-body__item">
                  <img
                    className="card-body__item-img"
                    src={blogCal}
                    width={14}
                    height={14}
                  />
                  <span className="card-body__item-tt">
                    {dateFormat(article?.date, "dd.mm.yyyy")}
                  </span>
                </li>
              </ul>
              <h3 className="card-detail__title">{article?.title}</h3>
              <div
                className="card-detail__text"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {article?.text}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default withTranslation()(BlogOpen);
