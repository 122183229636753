import { Button, Modal } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import url from "../../url.json";
import axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import headers from "../../config/headers";

function ModalDocDel(props) {
  const { t } = useTranslation();
  const handleOk = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .delete(url.url + `/document/${props.res.obj.id}`, { headers: headers() })
      .then((res) => {
        if (res.status == 200) {
          toast.success(t("Success!"));
          props.handleCancel();
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        toast.error(err.response.data.error);
      });
  };

  return (
    <>
      <Modal
        title={t("Do you want to delete?")}
        visible={props.show}
        onOk={handleOk}
        okText={t("Delete")}
        cancelText={t("Cancel")}
        onCancel={() => props.handleCancel()}
      >
        <div className="mod-container">
          <div className="mod-inner-holder">
            <div className="mod-icon">{props.res?.icon}</div>
            <span className="mod-file-name">{props.res?.obj.name}</span>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(ModalDocDel);
