import React from "react";
import CheckoutPage from "../../components/ChekoutPage/ChekoutPage";
import "./style.scss"

export default function Register() {
  return (
    <div className="wrapper-wrapper">
    
      <CheckoutPage />
    </div>
  );
}
