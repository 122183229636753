import { Button, Modal } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import url from "../../url.json";
import axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import headers from "../../config/headers";

function ModalDelete(props) {
  const { t } = useTranslation();
  const handleOk = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .delete(url.url + `/application/${props.res.id}`, { headers: headers() })
      .then((res) => {
        if (res.status == 200) {
          toast.success(t("Success!"));
          props.handleCancel();
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        toast.error(err.response.data.error);
      });
  };

  return (
    <>
      <Modal
        title={t("Do you want to delete?")}
        visible={props.show}
        onOk={handleOk}
        okText={t("Delete")}
        cancelText={t("Cancel")}
        onCancel={() => props.handleCancel()}
      >
        <div className="mod-container">
          <h3 className="mod-file-title">{props?.res?.code}</h3>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(ModalDelete);
