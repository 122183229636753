import React, { useState, useEffect } from "react";
import "./Landing.scss";
import LandingFoot from "./LandingFoot/LandingFoot";
import LandingHead from "./LandingHead/LandingHead";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getprofilAsync } from "../../Redux/Reducers/Profil/profilSlice";

function Landing() {
  const open = useSelector((state) => state.open);
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("token"))
      dispatch(getprofilAsync("legal-entity/profile"));
  }, []);
  return (
    <div className={open.value}>
      <LandingHead />
      <section className="landing-main">
        <Outlet />
      </section>
      <LandingFoot />
    </div>
  );
}

export default Landing;
