import React from "react";
import "./ModalLogout.scss";
import { useTranslation, withTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import url from "../../../url.json";
import axios from "axios";
import { useDispatch } from "react-redux";
import { tokenReducer } from "../../../Redux/Reducers/Token/TokenReducer";
import { toast } from "react-toastify";
function ModalLogout(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleClickLog = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axios
      .post(url.url + "/logout")
      .then((res) => {
        dispatch(tokenReducer(null));
        props.onClose();
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  if (!props.show) {
    return null;
  }
  return (
    <div onClick={props.onClose} className="modal">
      <div onClick={(e) => e.stopPropagation()} className="modal-gs">
        <div className="modal-head">
          <h3 className="modal-head__title">{t("Logout")}</h3>
          <button
            onClick={props.onClose}
            className="modal-head__btn"
            type="button"
          ></button>
        </div>
        <div className="modal-body">
          <div className="modalmain">
            <div className="modalmain__text"> {t("Are you sure")} ?</div>
            <div className="modalmain__btn">
              <Button variant="contained" onClick={props.onClose}>
                {t("Cancel")}
              </Button>

              <Button
                variant="contained"
                color="error"
                onClick={handleClickLog}
              >
                {t("Delete")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(ModalLogout);
