import "./layout.scss";
import React, { useEffect } from "react";
import { Header, Sidebar } from "../../components";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getprofilAsync } from "../../Redux/Reducers/Profil/profilSlice";

export default function Layout() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (JSON.parse(localStorage.getItem("user"))?.legal_entity == null) {
        window.location.href = "/register/last-step";
      }
      dispatch(getprofilAsync("legal-entity/profile"));
    } else {
      window.location.href = "/login";
    }
  }, []);
  return (
    <div className="page-container">
      <div className="site-header">
        <Header />
      </div>
      <div className="page-main">
        <div className="page-main__wrapper containerrr">
          <div className="sidebar-bar">
            <Sidebar />
          </div>
          <div className="outlet outlet-container">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
