import { createSlice } from "@reduxjs/toolkit";

export const aboutSlice = createSlice({
  name: "about",
  initialState: { value: {} },
  reducers: {
    aboutReducer: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { aboutReducer } = aboutSlice.actions;
export default aboutSlice.reducer;
