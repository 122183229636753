import * as React from "react";
import { Spin } from "antd";
import "./styles.scss";
import url from "../../url.json";
import axios from "axios";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import headers from "../../config/headers.js";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import header from "../header/header";
import { tokenReducer } from "../../Redux/Reducers/Token/TokenReducer";
import { useSelector } from "react-redux";
function Friststep({ t }) {
  const lan = useSelector((state) => state.langReducer);
  const navigate = useNavigate();
  const [currenCountry, setcurrentCountry] = useState(1);
  const dispatch = useDispatch();
  const [country, setCountry] = useState();
  const [username, setUsername] = useState(true);
  const [email, setEmail] = useState(true);
  const [password, setPassword] = useState(true);
  const [confp, setconfp] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    axios.get(url.url + "/country", { headers: headers() }).then((res) => {
      setCountry(res.data);
    });
  }, []);
  const handleChange = () => {
    const usernames = document.getElementById("exampleUsername").value;
    const emails = document.getElementById("exampleEmail").value;
    const passwords = document.getElementById("examplePassword").value;
    const confps = document.getElementById("exampleconPassword").value;
    if (passwords != confps) setconfp(false);
    else setconfp(true);

    if (!usernames) setUsername(false);
    else setUsername(true);
    if (!emails) setEmail(false);
    else setEmail(true);
    if (!passwords) {
      setPassword(false);
      setconfp(false);
    } else {
      setPassword(true);
    }

    const data = {
      name: "Bek",
      username: usernames,
      email: emails,
      password: passwords,
      password_confirmation: confps,
      country_id: currenCountry,
    };

    axios
      .post(url.url + "/register2", data, { headers: headers() })
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          dispatch(tokenReducer(res.data.token));
          if (res.data.link) {
            window.location.href = res.data.link;
          } else window.location.href = "/register/last-step";
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setError(err.response.data.errors);
      });
  };
  return (
    <div>
      {country ? (
        <Form>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label className="lab" for="examplecountry">
                  {t("Country")}
                </Label>

                <Input
                  key={country?.data[0].id}
                  type="select"
                  defaultValue={"1"}
                  id="examplecountry"
                  className="inpp-select"
                  placeholder={t("country enter")}
                  onChange={(e) => setcurrentCountry(e.target.value)}
                >
                  {country?.data.map((res, i) => (
                    <option key={i} value={res.id}>
                      {res.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="exampleUsername">
                  {t("username1")}
                </Label>
                {username && !error?.username ? (
                  <Input
                    className="inpp"
                    id="exampleUsername"
                    name="Username"
                    placeholder={t("Username enter")}
                    type="username"
                  />
                ) : (
                  <Input
                    className="inpp invalid"
                    id="exampleUsername"
                    name="Username"
                    invalid
                    placeholder={t("Username enter")}
                    type="username"
                  />
                )}
                {error ? <FormFeedback>{error.username}</FormFeedback> : ""}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="exampleEmail">
                  {t("Email")}
                </Label>
                {email && !error?.email ? (
                  <Input
                    className="inpp"
                    id="exampleEmail"
                    name="email"
                    placeholder={t("email enter")}
                    type="email"
                  />
                ) : (
                  <Input
                    className="inpp invalid"
                    invalid
                    id="exampleEmail"
                    name="email"
                    placeholder={t("email enter")}
                    type="email"
                  />
                )}
                {error ? <FormFeedback>{error.email}</FormFeedback> : ""}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="examplePassword">
                  {t("Password")}
                </Label>
                {password && !error?.password ? (
                  <Input
                    className="inpp"
                    id="examplePassword"
                    name="password"
                    placeholder={t("Password entry")}
                    type="password"
                  />
                ) : (
                  <Input
                    className="inpp invalid"
                    invalid
                    id="examplePassword"
                    name="password"
                    placeholder={t("Password entry")}
                    type="password"
                  />
                )}
                {error ? <FormFeedback>{error.password}</FormFeedback> : ""}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="exampleconPassword">
                  {t("Password confirmation")}
                </Label>
                {confp ? (
                  <Input
                    className="inpp"
                    id="exampleconPassword"
                    name="conpassword"
                    placeholder={t("Password confirmation entry")}
                    type="password"
                  />
                ) : (
                  <Input
                    className="inpp invalid"
                    invalid
                    id="exampleconPassword"
                    name="conpassword"
                    placeholder={t("Password confirmation entry")}
                    type="password"
                  />
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="d-flex justify-content-center">
              {currenCountry == 1 ? (
                <button className="btnn" onClick={handleChange} type="button">
                  {t("Registration through ERI")}
                </button>
              ) : (
                <button className="btnn" onClick={handleChange} type="button">
                  {t("Next")}
                </button>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-4" style={{ textAlign: "center" }}>
              {lan.value == "uz" ? (
                <p style={{ fontSize: 12, color: "#90a0b7" }}>
                  Ro’yxatan o’tish orqali siz <Link to="/offer">oferta</Link>{" "}
                  shartlariga rozi ekaningizni bildirasiz
                </p>
              ) : lan.value == "ru" ? (
                <p style={{ fontSize: 12, color: "#90a0b7" }}>
                  Регистрируясь, вы соглашаетесь с условиями{" "}
                  <Link to="/offer">оферты</Link>
                </p>
              ) : (
                <p style={{ fontSize: 12, color: "#90a0b7" }}>
                  By registering, you agree to the terms of the{" "}
                  <Link to="/offer">offer</Link>
                </p>
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <Spin>
          {" "}
          <Form>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label className="lab" for="examplecountry">
                    {t("Country")}
                  </Label>

                  <Input
                    key={country?.data[0].id}
                    type="select"
                    defaultValue={"1"}
                    id="examplecountry"
                    className="inpp-select"
                    placeholder={t("country enter")}
                    onChange={(e) => setcurrentCountry(e.target.value)}
                  >
                    {country?.data.map((res, i) => (
                      <option key={i} value={res.id}>
                        {res.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="lab" for="exampleUsername">
                    {t("Username")}
                  </Label>
                  {username && !error?.username ? (
                    <Input
                      className="inpp"
                      id="exampleUsername"
                      name="Username"
                      placeholder={t("Username enter")}
                      type="username"
                    />
                  ) : (
                    <Input
                      className="inpp invalid"
                      id="exampleUsername"
                      name="Username"
                      invalid
                      placeholder={t("Username enter")}
                      type="username"
                    />
                  )}
                  {error ? <FormFeedback>{error.username}</FormFeedback> : ""}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="lab" for="exampleEmail">
                    {t("Email")}
                  </Label>
                  {email && !error?.email ? (
                    <Input
                      className="inpp"
                      id="exampleEmail"
                      name="email"
                      placeholder={t("email enter")}
                      type="email"
                    />
                  ) : (
                    <Input
                      className="inpp invalid"
                      invalid
                      id="exampleEmail"
                      name="email"
                      placeholder={t("email enter")}
                      type="email"
                    />
                  )}
                  {error ? <FormFeedback>{error.email}</FormFeedback> : ""}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="lab" for="examplePassword">
                    {t("Password")}
                  </Label>
                  {password && !error?.password ? (
                    <Input
                      className="inpp"
                      id="examplePassword"
                      name="password"
                      placeholder={t("Password entry")}
                      type="password"
                    />
                  ) : (
                    <Input
                      className="inpp invalid"
                      invalid
                      id="examplePassword"
                      name="password"
                      placeholder={t("Password entry")}
                      type="password"
                    />
                  )}
                  {error ? <FormFeedback>{error.password}</FormFeedback> : ""}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="lab" for="exampleconPassword">
                    {t("Password confirmation")}
                  </Label>
                  {confp ? (
                    <Input
                      className="inpp"
                      id="exampleconPassword"
                      name="conpassword"
                      placeholder={t("Password confirmation entry")}
                      type="password"
                    />
                  ) : (
                    <Input
                      className="inpp invalid"
                      invalid
                      id="exampleconPassword"
                      name="conpassword"
                      placeholder={t("Password confirmation entry")}
                      type="password"
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="d-flex justify-content-center">
                {currenCountry == 1 ? (
                  <button className="btnn" onClick={handleChange} type="button">
                    {t("Registration through ERI")}
                  </button>
                ) : (
                  <button className="btnn" onClick={handleChange} type="button">
                    {t("Next")}
                  </button>
                )}
              </Col>
            </Row>
            <div
              // className="mt-5"
              style={{
                display: "flex",
                justifyItems: "center",
                textAlign: "center",
              }}
            >
              {lan.value == "uz" ? (
                <p style={{ fontSize: 12, color: "#90a0b7" }}>
                  Ro’yxatan o’tish orqali siz <Link to="/offer">oferta</Link>{" "}
                  shartlariga rozi ekaningizni bildirasiz
                </p>
              ) : lan.value == "ru" ? (
                <p style={{ fontSize: 12, color: "#90a0b7" }}>
                  Регистрируясь, вы соглашаетесь с условиями{" "}
                  <Link to="/offer">оферты</Link>
                </p>
              ) : (
                <p style={{ fontSize: 12, color: "#90a0b7" }}>
                  By registering, you agree to the terms of the{" "}
                  <Link to="/offer">offer</Link>
                </p>
              )}
            </div>
          </Form>
        </Spin>
      )}
    </div>
  );
}
export default withTranslation()(Friststep);
