import { Routes, Route, Navigate } from "react-router-dom";
import "./sass/main.scss";
import { Layout, Login, Register, Landing, Settings } from "./pages";
import { Suspense, useEffect } from "react";
import { RegulLand, FaqLand, HomeLand, Reestr } from "./pages/landing";
import { useDispatch } from "react-redux";
import { langReducer } from "./Redux/Reducers/Lang/langReducer";
import Register2 from "./pages/register/Register2";
import { tokenReducer } from "./Redux/Reducers/Token/TokenReducer";
import Applications from "./pages/Applications/Applications";
import Addapplication from "./pages/AddApplic/Addapplication";
import "react-toastify/dist/ReactToastify.css";
import Document from "./components/Document/Document";
import Pay from "./components/Pay/Pay";
import ShowApplication from "./pages/Applications/ShowApplication";
import EditApplication from "./pages/Applications/EditApplication";
import Profile from "./pages/Profile/Profile";
import DocumentShow from "./components/Document/DocumentShow";
import BlogOpen from "./pages/landing/Blog/BlogOpen";
import Forgot from "./pages/landing/Forgot";
import { ToastContainer } from "react-toastify";
import ForgotPass from "./pages/landing/ForgotPass";
import Contract from "./pages/Contract/Contract";
import NotOpen from "./pages/NotOpen/NotOpen";
import Notification from "./components/Notifications/Notification";
import ContractPay from "./components/Pay/ContractPay";
import DocumentEditable from "./components/Document/DocumentEditable";
import MySertificates from "./pages/MySertificate/MySertificates";
import NotFound from "./pages/404/404";
import Instruction from "./components/Intruction/Intruction";
import { useSelector } from "react-redux";
import Instructioneditable from "./components/Intruction/Instructioneditable";
import Ofer from "./pages/landing/Ofer";
import CheckCertificate from "./pages/landing/CheckCertificate";
import { getprofilAsync } from "./Redux/Reducers/Profil/profilSlice";

function App() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile?.data[0]);
  useEffect(() => {
    if (!localStorage.getItem("lan")) {
      localStorage.setItem("lan", "uz");
    }

    dispatch(langReducer(localStorage.getItem("lan")));
    dispatch(tokenReducer(localStorage.getItem("token")));
  }, []);

  return (
    <Suspense fallback>
      <ToastContainer />
      <Routes>
        <Route path="/account" element={<Layout />}>
          <Route path="/account/applications" element={<Applications />} />
          <Route path="/account/applicationsadd" element={<Addapplication />} />
          <Route
            path="/account/applications/show"
            element={<ShowApplication />}
          />
          <Route path="/account/contract" element={<Contract />} />
          <Route path="/account/mycertificates" element={<MySertificates />} />

          <Route path="/account/contract/paylist" element={<ContractPay />} />
          <Route path="/account/notic" element={<Notification />} />
          <Route path="/account/notic-open" element={<NotOpen />} />
          <Route
            path="/account/applications/edit"
            element={<EditApplication />}
          />
          <Route
            path="/account/applications/instruction"
            element={<Instruction />}
          />
          <Route path="/account/applications/document" element={<Document />} />
          <Route
            path="/account/applications/documentshow"
            element={<DocumentShow />}
          />
          <Route
            path="/account/applications/documenteditable"
            element={<DocumentEditable />}
          />
          <Route
            path="/account/applications/insturictioneditable"
            element={<Instructioneditable />}
          />
          <Route path="/account/settings" element={<Settings />} />
          <Route path="/account/profile" element={<Profile />} />
          <Route path="/account/applications/pay" element={<Pay />} />
        </Route>
        <Route path="" element={<Landing />}>
          <Route index element={<HomeLand />} />
          <Route path="reestr" element={<Reestr />} />
          <Route path="checkcertificate" element={<CheckCertificate />} />

          <Route path="/404" element={<NotFound />} />
          <Route path="offer" element={<Ofer />} />

          <Route path="faq" element={<FaqLand />} />
          <Route path="forgotpassword" element={<Forgot />} />
          <Route path="createpassword" element={<ForgotPass />} />
          <Route path="blogopen" element={<BlogOpen />} />
          <Route path="regulations" element={<RegulLand />} />
          <Route
            path="register/frist-step"
            element={
              !localStorage.getItem("token") ? (
                <Register />
              ) : (
                <Navigate to="/account/applications" replace />
              )
            }
          />
          <Route
            path="register/last-step"
            element={
              localStorage.getItem("token") &&
              JSON.parse(localStorage.getItem("user"))?.legal_entity == null ? (
                <Register2 />
              ) : (
                <Navigate to="/account/applications" replace />
              )
            }
          />
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="*" exact={true} element={<Navigate to="/404" replace />} />
      </Routes>
    </Suspense>
  );
}

export default App;
