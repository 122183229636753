import "./style.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import LastReg from "../../components/ChekoutPage/LastReg";

export default function Register() {
  return (
    <div className="wrapper-wrapper">
      <LastReg />
    </div>
  );
}
