import React, { useRef, useState, useEffect } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default function TextEditor({ id, key, value, onChange, disabled }) {
  const [editorData, setEditorData] = useState(value ?? "");

  useEffect(() => {
    setEditorData(value);
  }, [value]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    if (onChange) {
      onChange(data);
    }
  };
  return (
    <div className="ckeditor-classic">
      <CKEditor
        id={id}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "undo",
            "redo",
          ],
        }}
        editor={ClassicEditor}
        disabled={disabled}
        key={key}
        data={editorData ?? ""}
        onChange={handleEditorChange}
      />
      {/* <Editor
        style={{ height: 200 }}
        disabled={disabled}
        apiKey="deezf0cl00xsz6h8xs6dxt1ilrdtscaj0sg5xa43r2juifm6"
        // onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={onChange}
        onInit={onInit}
        value={value}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      /> */}
    </div>
  );
}
