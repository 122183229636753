import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Logo from "../../assets/icon/logo.png";
import FeedIcon from "@mui/icons-material/Feed";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import url from "../../url.json";
import axios from "axios";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { tokenReducer } from "../../Redux/Reducers/Token/TokenReducer";
import { withTranslation } from "react-i18next";
import { getprofilAsync } from "../../Redux/Reducers/Profil/profilSlice";
import ModalLogout from "../Modal/Logout/ModalLogout";
import "./header.scss";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function AccountMenu({ t }) {
  const [show, setShow] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function cancelModal() {
    setIsOpen(false);
  }
  const [anchorEl, setAnchorEl] = useState(null);
  let navigate = useNavigate();
  const [Setting, setSetting] = useState();
  const dispatch = useDispatch();
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  const user = useSelector((state) => state.profile?.data);

  useEffect(() => {
    setSetting(user[0]?.data);
  }, [user]);
  const handleClickLog = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axios
      .post(url.url + "/logout")
      .then((res) => {
        setIsOpen(false);
        dispatch(tokenReducer(null));
        toast.success(t("Success!"));
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        window.location.href = "/";
      });
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Account settings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {Setting?.avatar !== null ? (
              <Avatar sx={{ width: 32, height: 32 }}>
                <img
                  className="sidebar-entrance__img"
                  src={Setting ? Setting.avatar : ""}
                  // alt="site logo"
                />
              </Avatar>
            ) : (
              <Avatar
                sx={{ width: 32, height: 32 }}
                style={{ textTransform: "uppercase" }}
              >
                {Setting?.username.charAt(0)}
              </Avatar>
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/account/profile");
            dispatch(getprofilAsync("legal-entity/profile"));
          }}
        >
          {Setting?.avatar !== null ? (
            <Avatar sx={{ width: 32, height: 32 }}>
              <img
                className="sidebar-entrance__img"
                src={Setting ? Setting.avatar : ""}
                // alt="site logo"
              />
            </Avatar>
          ) : (
            <Avatar
              sx={{ width: 32, height: 32 }}
              style={{ textTransform: "uppercase" }}
            >
              {JSON.parse(localStorage.getItem("user"))?.username.charAt(0)}
            </Avatar>
          )}
          {t("Profile")}
          <div style={{ marginLeft: "auto", display: "flex" }}>
            {" "}
            {user[0]?.data.legal_entity?.blocked_at === null ? (
              user[0]?.data.legal_entity?.verified_at ? (
                <i className="tick-icon bx bxs-check-circle"></i>
              ) : (
                <i className="x-icon  bx bxs-x-circle"></i>
              )
            ) : (
              <i className="x-icon x-danger bx bxs-x-circle"> </i>
            )}
          </div>
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={() => {
            navigate("/account/applications");
          }}
        >
          <ListItemIcon>
            <FeedIcon fontSize="small" />
          </ListItemIcon>
          {t("Application")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/account/settings");
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("Settings")}
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(true)}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
      <Modal
        //! modal codes
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={cancelModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modalmain">
          <div className="modalmain__text">{t("Do you want to log out?")}</div>
          <div className="modalmain__btn">
            <button
              className="landing-header__btn signin"
              type="button"
              onClick={cancelModal}
            >
              {t("Cancel")}
            </button>
            <button
              className="landing-header__btn signup"
              type="button"
              onClick={handleClickLog}
            >
              {t("Logout")}
            </button>
          </div>
        </div>
      </Modal>
      <ModalLogout onClose={() => setShow(false)} show={show} />
    </React.Fragment>
  );
}
export default withTranslation()(AccountMenu);
