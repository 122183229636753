import React from "react";
import axios from "axios";
import url from "../../url.json";
import { useEffect } from "react";
import headers from "../../config/headers";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
function Ofer({ t }) {
  const [data, setData] = useState();
  const lang = useSelector((state) => state.langReducer.value);
  useEffect(() => {
    axios(url.url + "/oferta", { headers: headers() })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }, [lang]);

  return (
    <div className="res-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Offer")}</title>
        <meta name="description" content={t("herotitle")} />
      </Helmet>
      <h2 className="landing-reg__title"> {t("Offer")}</h2>
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: data?.data }} />
      </div>
    </div>
  );
}
export default withTranslation()(Ofer);
