import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "./badge.scss";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import dateFormat from "dateformat";
import { toast } from "react-toastify";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useSelector } from "react-redux";
function Badge({ t }) {
  const navigate = useNavigate();
  const [datas, setData] = useState({});
  const [count, setCount] = useState();
  const notice = useSelector((state) => state.notic);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios
      .get(url.url + `/notice?search=read`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
    axios
      .get(url.url + `/notice?search=read&&count=count`, {
        headers: headers(),
      })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [notice]);
  return (
    <div className="bgd">
      <div className="bgd-head">
        <div className="bgd-head__wr">
          <h3 className="bgd-head__title">{t("Notifications")} </h3>
          <div className="circ">{count}</div>
        </div>
        <Link className="bgd-head__link" to={{ pathname: "/account/notic" }}>
          {t("View all")}
        </Link>
      </div>
      <div className="bgd-body">
        <ul className="bgd-body__list">
          {datas?.result?.slice(-3).map((res, i) => (
            <li key={i} className="bgd-body__item">
              <Link
                className="bgd-name"
                to={{
                  pathname: "/account/notic-open",
                  search: `?${createSearchParams({
                    id: res.id,
                  })}`,
                }}
              >
                {res.message}
              </Link>
              <span className="bgd-date">
                {res.created_at ? dateFormat(res.created_at, "dd.mm.yyyy") : ""}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default withTranslation()(Badge);
