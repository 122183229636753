import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import "./style.scss";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import url from "../../../url.json";
import headers from "../../../config/headers";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { Empty, Pagination, Spin } from "antd";
import { Helmet } from "react-helmet-async";
import { Input } from "reactstrap";
import { FilterOutlined } from "@ant-design/icons";

function Reestr({ t }) {
  const [country, setCountry] = useState();

  const lan = useSelector((state) => state.langReducer.value);
  function Row(props) {
    const { t } = useTranslation();
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow onClick={() => setOpen(!open)}>
          <TableCell style={{ color: "#707683", borderBottom: "none" }}>
            {row.number}
          </TableCell>
          <TableCell style={{ color: "#707683", borderBottom: "none" }}>
            {dateFormat(row.start_date, "dd.mm.yyyy")}
          </TableCell>
          <TableCell style={{ color: "#707683", borderBottom: "none" }}>
            {row.drug.name}
          </TableCell>
          <TableCell style={{ color: "#707683", borderBottom: "none" }}>
            {row.drug.country.name}
          </TableCell>
          <TableCell style={{ borderBottom: "none" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <div className="wrrr" size="small" arial-label="bought">
                  <p
                    className="rester-name "
                    style={{ fontSize: 24, color: "#144789" }}
                  >
                    {t("Applicant_Information")}{" "}
                  </p>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Applicant")} </p>
                    <p className="rester-title">{row.legal_entity?.name}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Name")} </p>
                    <p className="rester-title">
                      {row.legal_entity?.boss_name}
                    </p>
                  </div>{" "}
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Email")} </p>
                    <p className="rester-title">{row.legal_entity?.email}</p>
                  </div>{" "}
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Address")} </p>
                    <p className="rester-title">{row.legal_entity?.address}</p>
                  </div>{" "}
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Phone")} </p>
                    <p className="rester-title">{row.legal_entity?.phone}</p>
                  </div>
                  <div className="head-name-rester"></div>
                  <p
                    className="rester-name "
                    style={{ fontSize: 24, color: "#144789" }}
                  >
                    {t("Drug Information")}{" "}
                  </p>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Drug name")} </p>
                    <p className="rester-title"> {row.drug.name}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Trade name")} </p>
                    <p className="rester-title"> {row.drug.trade_name}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Drug form")} </p>
                    <p className="rester-title">
                      {" "}
                      {row.drug.drug_figure?.name}
                    </p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Content")} </p>
                    <p className="rester-title"> {row.drug.structure}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Manufacturer country")} </p>
                    <p className="rester-title">{row.drug.country.name}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Manufacturer")} </p>
                    <p className="rester-title">{row.drug.manufacturer.name}</p>
                  </div>{" "}
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Preparer")} </p>
                    <p className="rester-title"> {row.drug.preparer.name}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Applicat")} </p>
                    <p className="rester-title"> {row.drug.usage}</p>
                  </div>
                  <div className="head-name-rester"></div>
                  <p
                    className="rester-name "
                    style={{ fontSize: 24, color: "#144789" }}
                  >
                    {t("Instruction")}{" "}
                  </p>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("general")} </p>
                    <div
                      className="rester-title"
                      dangerouslySetInnerHTML={{
                        __html: row.drug.instruction.general
                          ? row.drug.instruction.general[lan]
                          : "",
                      }}
                    ></div>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("pharmacological")} </p>
                    <div
                      className="rester-title"
                      dangerouslySetInnerHTML={{
                        __html: row.drug.instruction.pharmacological
                          ? row.drug.instruction.pharmacological[lan]
                          : "",
                      }}
                    ></div>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("prevention")} </p>
                    <div
                      className="rester-title"
                      dangerouslySetInnerHTML={{
                        __html: row.drug.instruction.prevention
                          ? row.drug.instruction.prevention[lan]
                          : "",
                      }}
                    ></div>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("protest")} </p>
                    <div
                      className="rester-title"
                      dangerouslySetInnerHTML={{
                        __html: row.drug.instruction.protest
                          ? row.drug.instruction.protest[lan]
                          : "",
                      }}
                    ></div>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("usage")} </p>
                    <div
                      className="rester-title"
                      dangerouslySetInnerHTML={{
                        __html: row.drug.instruction.usage
                          ? row.drug.instruction.usage[lan]
                          : "",
                      }}
                    ></div>
                  </div>
                  <div className="head-name-rester"></div>
                  <p
                    className="rester-name "
                    style={{ fontSize: 24, color: "#144789" }}
                  >
                    {t("Sertificat")}{" "}
                  </p>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Registration number")} </p>
                    <p className="rester-title"> {row.number}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Date range")} </p>
                    <p className="rester-title">
                      {" "}
                      {dateFormat(row.start_date, "dd.mm.yyyy")} -{" "}
                      {dateFormat(row.end_date, "dd.mm.yyyy")}
                    </p>
                  </div>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const [data, setData] = useState();
  useEffect(() => {
    axios.get(url.url + "/country", { headers: headers() }).then((res) => {
      setCountry(res.data);
    });
    axios
      .get(url.url + "/certificate", { headers: headers() })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        toast.error(err.response.message);
      });
  }, [lan]);
  const handlePage = (e) => {
    axios
      .get(url.url + `/certificate?page=${e}`, { headers: headers() })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  const filtered = () => {
    const number = document.getElementById("sertificate").value;
    const name = document.getElementById("drug").value;
    const start_date = document.getElementById("date").value;
    const country_id = document.getElementById("examplecountry").value;
    axios
      .get(
        url.url +
          `/certificate?number=${number}&name=${name}&start_date=${start_date}&country_id=${country_id}`,
        { headers: headers() }
      )
      .then((res) => {
        setData(res.data.data);
      });
  };
  return (
    <div className="res-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Reestr")}</title>
        <meta name="description" content={t("herotitle")} />
      </Helmet>
      <h2 className="landing-reg__title"> {t("Reestr")}</h2>
      <div className="res-wrapper-inner landing-container">
        <TableContainer
          component={Paper}
          style={{
            boxShadow: "none",
            border: "1px solid #e9e9e9",
            borderRadius: 15,
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  <Input
                    className="inpp"
                    id="sertificate"
                    name="sertificate"
                    placeholder={t("Registration number")}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  <Input
                    className="inpp"
                    id="date"
                    name="date"
                    type="date"
                    defaultValue={new Date()}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  <Input
                    className="inpp"
                    id="drug"
                    name="drug"
                    placeholder={t("Drug name")}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  <select
                    style={{ color: "#495057" }}
                    id="examplecountry"
                    className="inpp-select"
                    placeholder={t("country enter")}
                  >
                    <option value="" style={{ color: "#495057" }}>
                      {t("Country")}
                    </option>
                    {country?.data.map((res, i) => (
                      <option key={i} value={res.id}>
                        {res.name}
                      </option>
                    ))}
                  </select>
                </TableCell>
                <TableCell>
                  <button
                    className="landing-header__btn signin"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => filtered()}
                  >
                    <div style={{ marginRight: 10 }}>
                      <FilterOutlined />
                    </div>
                    {t("Filter")}
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  {t("Registration number")}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  {t("Registration date")}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  {t("Drug name")}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#109cf1",
                  }}
                >
                  {t("Country")}
                </TableCell>
                <TableCell>
                  <button
                    className="landing-header__btn signin"
                    onClick={() =>
                      (window.location.href = url.url + "/export/certificate")
                    }
                  >
                    {" "}
                    {t("Download")}
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            {data?.result.length > 0 ? (
              <TableBody>
                {data?.result.map((row, i) => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {/* <Empty description={t("No data")} /> */}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {data?.result.length > 0 ? (
          <div className="pagin">
            <Pagination
              pageSize={data?.pagination?.per_page}
              style={{ borderRadius: 15 }}
              onChange={(e) => handlePage(e)}
              defaultCurrent={1}
              total={data?.pagination?.total}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default withTranslation()(Reestr);
