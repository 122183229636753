import React, { useEffect, useState } from "react";
import { Row, Col, Label } from "reactstrap";
import TextField from "@mui/material/TextField";
import "../AddForm1/form.scss";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import { MenuItem } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import dateFormat from "dateformat";
import { Button, Empty, Spin } from "antd";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { IconButton } from "@material-ui/core";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./styles.scss";
import { useSelector } from "react-redux";

const CustomDisableInput = styled(TextField)(() => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
    textTransform: "none",
  },
  ".MuiInputLabel-formControl.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
  },
}));

function ShowApplic(props) {
  const { t } = useTranslation();
  const [datas, setDatas] = useState();
  const [drugb, setDrugb] = useState(true);
  const [repb, setrepb] = useState(true);
  const navigate = useNavigate();
  const lan = useSelector((state) => state.langReducer);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios
      .get(url.url + `/application/${props.id}`, { headers: headers() })
      .then((res) => {
        setDatas(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [lan]);

  if (datas)
    return (
      <div>
        <div className="form-body-wrapp">
          <div className="frm-con" style={{ width: "100%" }}>
            <FormControl fullWidth>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="app-title">{t("Application_Information")}</h5>
              </div>
              <CustomDisableInput
                defaultValue={datas?.code}
                fullWidth
                label={t("Code")}
                variant="standard"
                className="text-field"
                type="text"
                disabled={true}
              />
              <CustomDisableInput
                defaultValue={datas?.stage.name}
                fullWidth
                label={t("Stage")}
                variant="standard"
                className="text-field"
                type="text"
                disabled={true}
              />

              <div className="status-filed">
                <div className="status-lable">{t("Status")}</div>
                <div className="succ-btn">
                  <div className={`btn-status outline-${datas?.status.color} `}>
                    {datas.status?.name}
                  </div>
                </div>
                {/* <div className="line-btn"></div> */}
              </div>
              <div className="status-filed">
                <div className="status-lable">{t("Sertificat")}</div>
                <div
                  className="succ-btn"
                  onClick={() => {
                    if (datas?.certificate?.file)
                      window.location.href = datas?.certificate?.file;
                  }}
                >
                  {datas?.certificate ? (
                    <Button
                      className="btnnn"
                      type="primary"
                      shape="round"
                      icon={<FileDownloadOutlinedIcon />}
                      size={12}
                      onClick={() =>
                        (window.location.href = datas?.certificate?.file)
                      }
                    >
                      {datas?.certificate?.number}
                    </Button>
                  ) : (
                    <Button
                      className="btnnn"
                      type="primary"
                      shape="round"
                      disabled
                      icon={<FileDownloadOutlinedIcon />}
                      size={12}
                    >
                      {datas?.certificate?.number}
                    </Button>
                  )}
                </div>
                {/* <div className="line-btn"></div> */}
              </div>
              <div className="status-filed">
                <div className="status-lable">{t("Status pay")}</div>
                <div className="succ-btn">
                  <div
                    className={
                      datas?.is_paid == 1
                        ? `btn-status outline-primary`
                        : `btn-status outline-dark`
                    }
                  >
                    {datas?.is_paid == 1 ? t("paid") : t("unpaid")}
                  </div>
                </div>
                {/* <div className="line-btn"></div> */}
              </div>

              {datas?.is_canceled &&<div className="status-filed">
                <div className="status-lable">{t("cancel")}</div>
                <div
                  className="succ-btn"
                  onClick={() => {
                    if (datas?.certificate?.file)
                      window.location.href = datas?.certificate?.file;
                  }}
                >
                  {datas?.cancel?.file ? (
                    <Button
                        className="btnnn"
                        type="primary"
                        shape="round"
                        icon={<FileDownloadOutlinedIcon />}
                        size={12}
                        onClick={() =>
                          (window.location.href = datas?.cancel?.file)
                        }
                      >
                        {t("Download")}
                      </Button>
                  ) : ""}
                </div>
              </div>}

              <CustomDisableInput
                defaultValue={dateFormat(datas?.created_at, "dd.mm.yyyy")}
                fullWidth
                label={t("Date")}
                variant="standard"
                className="text-field"
                type="text"
                disabled={true}
              />
            </FormControl>
            <FormControl fullWidth>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="app-title">{t("Applicant_Information")}</h5>
              </div>
              <CustomDisableInput
                defaultValue={datas?.applicant}
                fullWidth
                label={t("Applicant")}
                variant="standard"
                className="text-field"
                type="text"
                disabled={true}
              />

              <CustomDisableInput
                defaultValue={datas?.representative.name}
                fullWidth
                className="text-field"
                label={t("Representative of the applicant")}
                variant="standard"
                type="text"
                disabled={true}
              />

              <CustomDisableInput
                disabled={repb}
                defaultValue={datas?.representative.address}
                className="text-field"
                fullWidth
                label={t("Address")}
                variant="standard"
                type="text"
              />
              <CustomDisableInput
                disabled={repb}
                defaultValue={datas?.representative?.fax}
                className="text-field"
                fullWidth
                label={t("Fax address")}
                variant="standard"
                type="text"
              />
              <CustomDisableInput
                disabled={repb}
                defaultValue={datas?.representative?.phone}
                className="text-field"
                fullWidth
                label={t("Phone number")}
                variant="standard"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 50 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="app-title">{t("Drug Information")}</h5>
              </div>

              <CustomDisableInput
                defaultValue={datas?.drug?.name}
                className="text-field"
                fullWidth
                disabled
                label={t("Drug")}
                variant="standard"
                type="text"
              />
              <CustomDisableInput
                disabled={drugb}
                defaultValue={datas?.drug?.trade_name}
                className="text-field"
                fullWidth
                label={t("Trade name")}
                variant="standard"
              />
              <CustomDisableInput
                disabled={drugb}
                defaultValue={datas?.drug?.structure}
                className="text-field"
                fullWidth
                label={t("Content")}
                variant="standard"
                type="text"
              />
              <CustomDisableInput
                disabled={drugb}
                defaultValue={datas?.drug?.drug_figure?.name}
                className="text-field"
                fullWidth
                label={t("Drug form")}
                variant="standard"
                type="text"
              />
              <CustomDisableInput
                disabled={drugb}
                defaultValue={datas?.drug?.usage}
                className="text-field"
                fullWidth
                label={t("Applicat")}
                variant="standard"
                type="text"
              />
              <CustomDisableInput
                disabled={drugb}
                defaultValue={datas?.drug?.patent}
                className="text-field"
                fullWidth
                label={t("Patent")}
                variant="standard"
                type="text"
              />
              <CustomDisableInput
                disabled={drugb}
                defaultValue={datas?.drug?.country?.name}
                className="text-field"
                fullWidth
                label={t("Country")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                disabled
                defaultValue={datas?.drug?.preparer.name}
                className="text-field"
                fullWidth
                label={t("Preparer")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                disabled
                defaultValue={datas?.drug?.preparer.address}
                className="text-field"
                fullWidth
                label={t("Address")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                defaultValue={datas?.drug?.preparer.fax}
                disabled
                className="text-field"
                fullWidth
                label={t("Fax address")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                disabled
                defaultValue={datas?.drug?.preparer.phone}
                className="text-field"
                fullWidth
                label={t("Phone number")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                disabled
                defaultValue={datas?.drug?.manufacturer.name}
                className="text-field"
                fullWidth
                label={t("Manufacturer")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                disabled
                defaultValue={datas?.drug?.manufacturer.address}
                className="text-field"
                fullWidth
                label={t("Address")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                defaultValue={datas?.drug?.manufacturer.fax}
                disabled
                fullWidth
                label={t("Fax address")}
                variant="standard"
                type="text"
              />

              <CustomDisableInput
                disabled
                defaultValue={datas?.drug?.manufacturer.phone}
                className="text-field"
                fullWidth
                label={t("Phone number")}
                variant="standard"
                type="text"
              />

              {/* <div
                className="info-header__wrapper date-form text-right"
                style={{ justifyContent: "flex-end" }}
              >
                <i className="info-header__icon bx bx-calendar date-icon-form"></i>
                <span className="info-header__text ">
                  {dateFormat(datas?.created_at, "dd.mm.yyyy")}
                </span>
              </div> */}
            </FormControl>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 500,
          backgroundColor: "white",
          borderRadius: 12,
        }}
      >
        <Spin tip={t("Loading...")}>
          <Empty description="" />
        </Spin>
      </div>
    );
}

export default withTranslation()(ShowApplic);
