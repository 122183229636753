import React, { useState } from "react";
import "./login.scss";
import axios from "axios";
import url from "../../url.json";
import { withTranslation } from "react-i18next";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import headers from "../../config/headers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { tokenReducer } from "../../Redux/Reducers/Token/TokenReducer";
import { IsLegalReducer } from "../../Redux/Reducers/IsLegal/IsLegal";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { getprofilAsync } from "../../Redux/Reducers/Profil/profilSlice";
function Login({ t }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokens = useSelector((state) => state.token);
  const [error, setError] = useState();
  const handleChange = () => {
    const usernames = document.getElementById("exampleUsername").value;
    const passwords = document.getElementById("examplePassword").value;
    const data = {
      username: usernames,
      password: passwords,
    };
    axios
      .post(url.url + "/login", data, { headers: headers() })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        dispatch(getprofilAsync("legal-entity/profile"));

        dispatch(IsLegalReducer(res.data.user.legal_entity));
        localStorage.setItem("user", JSON.stringify(res.data.user));
        dispatch(tokenReducer(res.data.token));
        if (res.data.user.legal_entity == null) {
          window.location.href = "/register/last-step";
        } else {
          window.location.href = "/account/applications";
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setError(err.response.data.errors);
      });
  };
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Login")}</title>
      </Helmet>
      <div className="forms_login">
        <div className="title">{t("Login")}</div>
        <Form>
          <FormGroup>
            <Label className="lab" for="exampleUsername">
              {t("Username")}
            </Label>
            {!error?.username ? (
              <Input
                className="inpp"
                id="exampleUsername"
                name="Username"
                placeholder={t("username")}
                type="username"
              />
            ) : (
              <Input
                className="inpp invalid"
                id="exampleUsername"
                name="Username"
                invalid
                placeholder={t("username")}
                type="username"
              />
            )}
            {error ? <FormFeedback>{error.username}</FormFeedback> : ""}
          </FormGroup>

          <FormGroup>
            <Label className="lab" for="examplePassword">
              {t("Password")}
            </Label>
            {!error?.password ? (
              <Input
                className="inpp"
                id="examplePassword"
                name="password"
                placeholder={t("Password entry")}
                type="password"
              />
            ) : (
              <div>
                <Input
                  className="inpp invalid"
                  invalid
                  id="examplePassword"
                  name="password"
                  placeholder={t("Password entry")}
                  type="password"
                />
                <FormFeedback>{error.password}</FormFeedback>
              </div>
            )}

            {error?.message ? (
              <div className="mt-1 invalid_feedback">{error?.message}</div>
            ) : (
              ""
            )}
          </FormGroup>

          <div className="row log-btn" style={{ justifyContent: "center" }}>
            <button type="button" className="login-bt" onClick={handleChange}>
              {t("Login")}
            </button>
          </div>
          <div className="action-log-btn">
            <div className="link_forgot">
              <Link to="/forgotpassword">{t("Forgot password")}</Link>
            </div>
            <div className="link_forgotr">
              <a href="register/frist-step">{t("Register now")}</a>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
export default withTranslation()(Login);
