import { Button, Modal } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import url from "../../url.json";
import axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import headers from "../../config/headers";

function SaveModal(props) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        footer={[
          <Button key="1" onClick={() => props.closeShow()}>
            {t("Cancel")}
          </Button>,
          <Button key="2" onClick={() => props.handleCancel()}>
            {t("Cancel_update")}
          </Button>,
          <Button key="3" type="primary" onClick={() => props.handleok()}>
            {t("Save")}
          </Button>,
        ]}
        title={t("Save the changes?")}
        visible={props.show}
        onOk={() => props.handleok()}
        okText={t("Save")}
        cancelText={t("Cancel")}
        onCancel={() => props.closeShow()}
      >
        <div className="mod-container">
          <div className="mod-containerr">
            <div className="mod-file">
              {/* <div>{props?.res?.code}</div> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(SaveModal);
