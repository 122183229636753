import React, { useEffect, useState } from "react";
import { Empty, Tabs, Steps } from "antd";
import Box from "@mui/material/Box";
import { Button, Checkbox, Form, Input, Select } from "antd";
import {
  FileWordOutlined,
  FileDoneOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import MainHeader from "../MainHeader/MainHeader";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import Tabss from "./Tabs";
import TextEditor from "../TextEditor";

const { Step } = Steps;
const { TabPane } = Tabs;
const { TextArea } = Input;

function ShowInstruction({ t }) {
  const lan = useSelector((state) => state.langReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [data, setDatas] = useState();
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState();
  const [general, setgeneral] = useState({ uz: "", ru: "", en: "" });
  const [pharmacological, setpharmacological] = useState({
    uz: "",
    ru: "",
    en: "",
  });
  const [usage, setusage] = useState({ uz: "", ru: "", en: "" });
  const [prevention, setprevention] = useState({ uz: "", ru: "", en: "" });
  useEffect(() => {
    axios
      .get(url.url + `/application/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setDatas(res.data.data);
        setgeneral(res.data.data.drug.instruction?.general);
        setpharmacological(res.data.data.drug.instruction?.pharmacological);
        setusage(res.data.data.drug.instruction?.usage);
        setprevention(res.data.data.drug.instruction?.prevention);

      })
      .catch((err) => {

      });
  }, []);


  function selects(key) {
    switch (key) {
      case "1":
        return (
          <div className="form-body ">
            <p className="rester-name mt-5">{t("general")}</p>
            <TextEditor
              disabled={true}
              key={"11"}
              id="uz"
              onChange={(value) => setgeneral({ ...general, uz: value })}
              value={general?.uz}
            />
            <p className="rester-name mt-5">{t("pharmacological")}</p>
            <TextEditor
              disabled={true}
              key={"12"}
              id="uz"
              onChange={(value) =>
                setpharmacological({ ...pharmacological, uz: value })
              }
              value={pharmacological?.uz}
            />
            <p className="rester-name mt-5">{t("usage")}</p>
            <TextEditor
              disabled={true}
              key={"13"}
              id="uz"
              onChange={(value) => setusage({ ...usage, uz: value })}
              value={usage?.uz}
            />
            <p className="rester-name mt-5">{t("prevention")}</p>
            <TextEditor
              disabled={true}
              key={"14"}
              id="uz"
              onChange={(value) => setprevention({ ...prevention, uz: value })}
              value={prevention?.uz}
            />
          </div>
        );
      case "2":
        return (
          <div className="form-body ">
            <p className="rester-name mt-5">{t("general")}</p>
            <TextEditor
              disabled={true}
              key={"21"}
              id="ru"
              onChange={(value) => setgeneral({ ...general, ru: value })}
              value={general?.ru}
            />
            <p className="rester-name mt-5">{t("pharmacological")}</p>
            <TextEditor
              disabled={true}
              key={"22"}
              id="ru"
              onChange={(value) =>
                setpharmacological({ ...pharmacological, ru: value })
              }
              value={pharmacological?.ru}
            />
            <p className="rester-name mt-5">{t("usage")}</p>
            <TextEditor
              disabled={true}
              key={"23"}
              id="ru"
              onChange={(value) => setusage({ ...usage, ru: value })}
              value={usage?.ru}
            />
            <p className="rester-name mt-5">{t("prevention")}</p>
            <TextEditor
              disabled={true}
              key={"24"}
              id="ru"
              onChange={(value) => setprevention({ ...prevention, ru: value })}
              value={prevention?.ru}
            />
          </div>
        );
      case "3":
        return (
          <div className="form-body ">
            <p className="rester-name mt-5">{t("general")}</p>
            <TextEditor
              disabled={true}
              key={"31"}
              id="en"
              onChange={(value) => setgeneral({ ...general, en: value })}
              value={general?.en}
            />
            <p className="rester-name mt-5">{t("pharmacological")}</p>
            <TextEditor
              disabled={true}
              key={"32"}
              id="en"
              onChange={(value) =>
                setpharmacological({ ...pharmacological, en: value })
              }
              value={pharmacological?.en}
            />
            <p className="rester-name mt-5">{t("usage")}</p>
            <TextEditor
              disabled={true}
              key={"33"}
              id="en"
              onChange={(value) => setusage({ ...usage, en: value })}
              value={usage?.en}
            />
            <p className="rester-name mt-5">{t("prevention")}</p>
            <TextEditor
              disabled={true}
              key={"34"}
              id="en"
              onChange={(value) => setprevention({ ...prevention, en: value })}
              value={prevention?.en}
            />
          </div>
        );

      default:
        break;
    }
  }
  const [keys, setKeys] = useState("1");

  const changeTab = (activeKey) => {
    setKeys(activeKey);
  };
  useEffect(() => {
    axios
      .get(url.url + `/application/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data.data.editable == 1) setEditable(true);
      });
  }, [lan]);

  return (
    <div>
      <div className="form-body-wrapp">
        <div className="frm-con" style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 className="app-title">{t("Instruction")}</h5>
          </div>
          <div className="tabb">
            <Box sx={{ width: "100%" }}>
              <Tabs defaultActiveKey="1" size={"large"} onChange={changeTab}>
                <TabPane tab={<span>{t("Uzbek")}</span>} key="1" />

                <TabPane tab={<span>{t("Russian")}</span>} key="2" />
                <TabPane animated tab={<span>{t("English")}</span>} key="3" />
              </Tabs>
            </Box>
          </div>
          {selects(keys)}
        </div>
      </div>

      {/* {editable ? (

      ) : (
      )} */}
    </div>
  );
}
export default withTranslation()(ShowInstruction);
