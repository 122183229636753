import { Button, Modal } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import url from "../../url.json";
import axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import headers from "../../config/headers";
import { TextField } from "@mui/material";

import {
  RetweetOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileJpgOutlined,
} from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
function ModalChat(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [title, setTitle] = useState();
  const [icon, setIcon] = useState();

  const openFile = () => {
    fileRef.current.click();
  };
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    setVisible(props.show);
    if (props.file?.name.split(".")[1] == "jpg") setIcon(<FileJpgOutlined />);
    if (props.file?.name.split(".")[1] == "pdf") setIcon(<FilePdfOutlined />);
    if (props.file?.name.split(".")[1] == "doc") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "docx") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "ppt") setIcon(<FilePptOutlined />);
    if (props.file?.name.split(".")[1] == "pptx") setIcon(<FilePptOutlined />);
    setFile(props.file);
  }, [props]);

  const handleOk = () => {
    const formData = new FormData();
    // data.append("application_id", props.res?.obj.application_id);
    // data.append("category_id", props.res?.obj.category_id);
    formData.append("application_id", searchParams.get("id"));
    formData.append("file", file);
    formData.append("message", title);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .post(url.url + "/message", formData, { headers: headers() })
      .then((res) => {
        // setRes(res.data);
        props.handleCancel();
        setTitle("");
      });
  };
  function handleChange(event) {
    if (event.target.files[0]?.name.split(".")[1] == "jpg")
      setIcon(<FileJpgOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "pdf")
      setIcon(<FilePdfOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "doc")
      setIcon(<FileWordOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "docx")
      setIcon(<FileWordOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "ppt")
      setIcon(<FilePptOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "pptx")
      setIcon(<FilePptOutlined />);
    setFile(event.target.files[0]);
  }
  return (
    <>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      {props.show ? (
        <Modal
          afterClose={() => setVisible(false)}
          title={t("Do you want to send file?")}
          visible={visible}
          onOk={handleOk}
          okText={t("Ok")}
          cancelText={t("Cancel")}
          onCancel={() => setVisible(false)}
          destroyOnClose={true}
        >
          <div className="mod-container">
            <div className="mod-inner">
              <div className="mod-inner-holder">
                <div className="mod-icon">{icon}</div>
                <div className="mod-file">
                  <h3 className="mod-file-title">{file?.name}</h3>
                  <span className="mod-file-name">
                    {Math.ceil(file?.size / 1024)} kb
                  </span>
                </div>
                <div className="mod-inner-icon">
                  <RetweetOutlined onClick={openFile} />
                </div>
              </div>
            </div>
            <div key={props}>
              <TextField
                className="text-field"
                fullWidth
                label={t("Message")}
                variant="standard"
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}

export default withTranslation()(ModalChat);
