import React, { useEffect } from "react";
import "../Landing.scss";
import Logo from "../../../assets/icon/logo.png";
import SelectLan from "../../../components/header/SelectLan";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Avatar from "../../../assets/icon/acc.svg";
import axios from "axios";
import url from "../../../url.json";
import Logout from "@mui/icons-material/Logout";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ModalLogout from "../../../components/Modal/Logout/ModalLogout";
import Modal from "react-modal";
import { tokenReducer } from "../../../Redux/Reducers/Token/TokenReducer";
import { openReducer } from "../../../Redux/Reducers/MenuOpen/MenuOpen";
import { activelandingReducer } from "../../../Redux/Reducers/LandingActive/activelanding";
import { langReducer } from "../../../Redux/Reducers/Lang/langReducer";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function LandingHead({ t }) {
  const active = useSelector((state) => state.activelanding);
  const [show, setShow] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  useEffect(() => {
    let a = window.location.href;
    dispatch(activelandingReducer(a.split("/")[3]));
  }, [window.location.href]);

  function cancelModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const OpenClick = () => {
    dispatch(openReducer("page-content opened"));
  };
  const handleClose = () => {
    dispatch(openReducer("page-content"));
  };
  const [logoav, setLogoav] = useState();

  const handleClick = () => {
    if (token.value) {
      navigate({ pathname: "/account/applications" });
    }
  };
  const handleClickLog = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axios
      .post(url.url + "/logout")
      .then((res) => {
        setIsOpen(false);
        dispatch(tokenReducer(null));
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  const scrollToID = async (id) => {
    await navigate("/");
    window.location.href = `#${id}`;
  };
  const selectlan = useSelector((state) => state.langReducer);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (selectlan.value == "uz") {
      i18n.changeLanguage("uz");
    }
    if (selectlan.value == "ru") {
      i18n.changeLanguage("ru");
    }
    if (selectlan.value == "en") {
      i18n.changeLanguage("en");
    }
  }, [selectlan.value]);

  const open = Boolean(anchorEl);
  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloses = (lng) => {
    setAnchorEl(null);
    localStorage.setItem("lan", lng);
    dispatch(langReducer(lng));
    i18n.changeLanguage(lng);
  };
  const res = useSelector((state) => state.about);

  return (
    <div className="landing-header">
      <div className="landing-header__wrapper landing-container">
        <Link
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="landing-header__link"
          to={{
            pathname: "/",
          }}
        >
          {res.value.logo ? (
            <img
              className="landing-header__logo"
              src={res.value?.logo}
              width={45}
              height={45}
            />
          ) : (
            ""
          )}
          <p className="landing-logo__tt"> Vis nefrit</p>
        </Link>
        <div onClick={handleClose} className="lists__wrapperr-hd">
          <div className="landing-listt__holder">
            <div className="sel-lang">
              <button
                className={
                  selectlan.value == "uz"
                    ? "sel-lang__btn sel-lang__btn--active"
                    : "sel-lang__btn"
                }
                type="button"
                onClick={() => handleCloses("uz")}
              >
                O'Z
              </button>
              <button
                className={
                  selectlan.value == "ru"
                    ? "sel-lang__btn sel-lang__btn--active"
                    : "sel-lang__btn"
                }
                type="button"
                onClick={() => handleCloses("ru")}
              >
                РУ
              </button>
              <button
                className={
                  selectlan.value == "en"
                    ? "sel-lang__btn sel-lang__btn--active"
                    : "sel-lang__btn"
                }
                type="button"
                onClick={() => handleCloses("en")}
              >
                EN
              </button>
            </div>
            <ul className="landing-header__list landing-list ">
              <li
                className="landing-list__item"
                onClick={() => scrollToID("about")}
              >
                <a
                  className={"landing-list__link"}
                  style={
                    active.value == "#about"
                      ? { color: "#109cf1" }
                      : { color: "white" }
                  }
                  href="#about"
                >
                  {t("About")}
                </a>
              </li>
              <li
                className="landing-list__item"
                onClick={() => scrollToID("blog")}
              >
                <a
                  className={"landing-list__link"}
                  style={
                    active.value == "#blog"
                      ? { color: "#109cf1" }
                      : { color: "white" }
                  }
                  href="#blog"
                >
                  {t("News")}
                </a>
              </li>
              <li
                className="landing-list__item"
                onClick={() => scrollToID("contact")}
              >
                <a
                  className={"landing-list__link"}
                  style={
                    active.value == "#contact"
                      ? { color: "#109cf1" }
                      : { color: "white" }
                  }
                  href="#contact"
                >
                  {t("Contact")}
                </a>
              </li>
              <li className="landing-list__item">
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "landing-list__link_active "
                      : "landing-list__link"
                  }
                  to={{
                    pathname: "/regulations",
                  }}
                >
                  {t("Regulations")}
                </NavLink>
              </li>
              <li className="landing-list__item">
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "landing-list__link_active "
                      : "landing-list__link"
                  }
                  to={{
                    pathname: "/reestr",
                  }}
                >
                  {t("Reestr")}
                </NavLink>
              </li>
              <li className="landing-list__item">
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "landing-list__link_active "
                      : "landing-list__link"
                  }
                  to={{
                    pathname: "/faq",
                  }}
                >
                  {t("FAQ")}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="landing-header__btns">
          <div className="lang-select">
            <SelectLan />
          </div>
          {!localStorage.getItem("token") ||
          JSON.parse(localStorage.getItem("user"))?.legal_entity == null ? (
            <div className="btn-wrapperr">
              <div className="btn-holderr">
                <button
                  className="landing-header__btn signup"
                  type="button"
                  onClick={() => navigate("/register/frist-step")}
                >
                  {t("Sign up")}
                </button>

                <button
                  className="landing-header__btn signin"
                  type="button"
                  onClick={() => navigate("/login")}
                >
                  {t("Sign in")}
                </button>
              </div>
              <button
                onClick={OpenClick}
                className="menu-btn"
                type="button"
              ></button>
            </div>
          ) : (
            <div className="btn-wrapperr">
              <div className="btn-holderr">
                <button
                  className="user-button"
                  type="button"
                  onClick={handleClick}
                  // style={{ width: 32, height: 32 }}
                >
                  <img
                    src={logoav ? logoav : Avatar}
                    style={{ width: 32, height: 32 }}
                  />
                </button>
                <button
                  className="user-button"
                  type="button"
                  onClick={() => setIsOpen(true)}
                >
                  <Logout
                    fontSize="small"
                    color="primary"
                    style={{ width: 30, height: 30, marginLeft: 20 }}
                  />
                </button>
              </div>
              <div className="menu-btnn">
                <button
                  onClick={OpenClick}
                  className="menu-btn"
                  type="button"
                ></button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        //! modal codes
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={cancelModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modalmain">
          <div className="modalmain__text">{t("Do you want to log out?")}</div>
          <div className="modalmain__btn">
            <button
              className="landing-header__btn signin"
              type="button"
              onClick={cancelModal}
            >
              {t("Cancel")}
            </button>
            <button
              className="landing-header__btn signup"
              type="button"
              onClick={handleClickLog}
            >
              {t("Logout")}
            </button>
          </div>
        </div>
      </Modal>
      <ModalLogout onClose={() => setShow(false)} show={show} />
    </div>
  );
}

export default withTranslation()(LandingHead);
