import React, { useState } from "react";
import "./styles.scss";
import { toast } from "react-toastify";
import {
  DownloadOutlined,
  FileAddOutlined,
  CreditCardOutlined,
  FileWordOutlined,
  SolutionOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Button, Radio } from "antd";
import PayList from "./PayList";
import ModalPay from "../Modal/ModalPay";
import { modalpayReducer } from "../../Redux/Reducers/Modal/modalpay";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Steps } from "antd";
import MainHeader from "../MainHeader/MainHeader";
import url from "../../url.json";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import headers from "../../config/headers";
const { Step } = Steps;
function Pay({ t }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const showModal = () => {
    dispatch(modalpayReducer(true));
  };
  const openFile = () => {
    fileRef.current.click();
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  const saveDocuments = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + `/application/contract/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        window.location.href = res.data.data?.file;
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  return (
    <>
      <ModalPay
        file={file}
        con_id={searchParams.get("con_id")}
        id={searchParams.get("id")}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <div className="add-application">
        <Box sx={{ width: "100%" }}>
          <Steps>
            <Step
              status="finish"
              title={t("Application")}
              icon={<SolutionOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (searchParams.get("con_id"))
                  navigate({
                    pathname: "/account/applications/edit",
                    search: `?${createSearchParams({
                      id: searchParams.get("id"),
                      con_id: searchParams.get("con_id"),
                    })}`,
                  });
                else
                  navigate({
                    pathname: "/account/applications/edit",
                    search: `?${createSearchParams({
                      id: searchParams.get("id"),
                    })}`,
                  });
              }}
            />
            <Step
              status="finish"
              title={t("Document")}
              icon={<FileWordOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (searchParams.get("con_id"))
                  navigate({
                    pathname: "/account/applications/document",
                    search: `?${createSearchParams({
                      id: searchParams.get("id"),
                      con_id: searchParams.get("con_id"),
                    })}`,
                  });
                else
                  navigate({
                    pathname: "/account/applications/document",
                    search: `?${createSearchParams({
                      id: searchParams.get("id"),
                    })}`,
                  });
              }}
            />
            <Step
              status="process"
              title={t("Pay")}
              icon={<CreditCardOutlined />}
              style={{ cursor: "pointer" }}
            />
          </Steps>
        </Box>
      </div>
      <div className="head-pay">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size={50}
          onClick={saveDocuments}
        >
          {t("Download the contract")}
        </Button>
        <Button
          type="primary"
          icon={<FileAddOutlined />}
          onClick={openFile}
          size={50}
        >
          {t("Upload receipt")}
        </Button>
      </div>
      <div className="pay-list">
        <PayList />
      </div>
      <div className="btn-gr">
        <div className="mr-3">
          <Button type="primary" size={20} onClick={() => navigate(-1)}>
            {t("Back")}
          </Button>
        </div>
        <div className="btn-pr-form">
          <Button
            type="primary"
            size={20}
            onClick={() => navigate("/account/applications")}
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    </>
  );
}
export default withTranslation()(Pay);
