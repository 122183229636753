import React, { useEffect, useState } from "react";
import "./Faq.scss";
import "../Landing.scss";
import { Collapse } from "antd";
import { withTranslation } from "react-i18next";
import axios from "axios";
import url from "../../../url.json";
import headers from "../../../config/headers";
import { Empty, Spin, Pagination } from "antd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
const { Panel } = Collapse;
function FaqLand({ t }) {
  const [faqs, setFaqs] = useState();
  const lan = useSelector((state) => state.langReducer);

  useEffect(() => {
    axios.get(url.url + "/faqs", { headers: headers() }).then((res) => {
      setFaqs(res.data.data);
    });
  }, [lan.value]);
  const handlePage = (e) => {
    axios
      .get(url.url + `/faqs?page=${e}`, { headers: headers() })
      .then((res) => {
        setFaqs(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  return (
    <div className="landing-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("FAQ")}</title>
      </Helmet>
      <div className="landing-faq">
        <h2 className="landing-faq__title"> {t("FAQ")}</h2>
        <Collapse accordion>
          {faqs ? (
            faqs.result.map((res, i) => (
              <Panel key={i} header={res.question}>
                <p style={{ whiteSpace: "pre-line", textAlign: "left" }}>
                  {res.answer}
                </p>
              </Panel>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 500,
                backgroundColor: "white",
              }}
            >
              <Spin tip={t("Loading...")}>
                <Empty description={t("No data")} />
              </Spin>
            </div>
          )}
        </Collapse>
      </div>
      <div className="pagin">
        <Pagination
          current={1}
          style={{ borderRadius: 15 }}
          onChange={(e) => handlePage(e)}
          defaultCurrent={1}
          total={faqs?.pagination.total}
        />
      </div>
    </div>
  );
}
export default withTranslation()(FaqLand);
