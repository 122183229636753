import { Button, Modal } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import url from "../../url.json";
import axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import headers from "../../config/headers";
import { TextField } from "@mui/material";
import {
  RetweetOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileJpgOutlined,
} from "@ant-design/icons";
function ModalDocEdit(props) {
  const { t } = useTranslation();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [title, setTitle] = useState();
  const [icon, setIcon] = useState();

  const openFile = () => {
    fileRef.current.click();
  };
  useEffect(() => {
    setTitle(props.res?.obj.name);
  }, [props.show]);
  const handleOk = () => {
    const data = new FormData();
    data.append("application_id", props.res?.obj.application_id);
    data.append("category_id", props.res?.obj.category_id);
    if (file) {
      data.append("file", file);
    }
    data.append("name", document.getElementById("standard-basic").value);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .post(url.url + `/document/${props.res?.obj.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success(t("Success!"));
          props.handleCancel();
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        toast.error(err.response.data.error);
      });
  };
  function handleChange(event) {
    if (event.target.files[0]?.name.split(".")[1] == "jpg")
      setIcon(<FileJpgOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "pdf")
      setIcon(<FilePdfOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "doc")
      setIcon(<FileWordOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "docx")
      setIcon(<FileWordOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "ppt")
      setIcon(<FilePptOutlined />);
    if (event.target.files[0]?.name.split(".")[1] == "pptx")
      setIcon(<FilePptOutlined />);
    if (event.target.files[0]) setFile(event.target.files[0]);
    setTitle(event.target.files[0].name.split(".")[0]);
  }
  return (
    <>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Modal
        title={t("Do you want to edit?")}
        visible={props.show}
        onOk={handleOk}
        onCancel={() => props.handleCancel()}
      >
        <div className="mod-container">
          <div className="mod-inner">
            <div className="mod-inner-holder">
              <div className="mod-icon">{icon ? icon : props.res?.icon}</div>
              <span className="mod-file-name">
                {file ? file?.name.split(".")[0] : props.res?.obj.name}
              </span>
            </div>
            <div className="mod-inner-icon">
              <RetweetOutlined onClick={openFile} />
            </div>
          </div>

          <div key={Math.random()}>
            <TextField
              className="text-field"
              id="standard-basic"
              variant="standard"
              type="text"
              defaultValue={title}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(ModalDocEdit);
