import React, { useState, useEffect } from "react";
import "./home.scss";
import { Empty } from "antd";
import "antd/dist/antd.min.css";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Home({ t }) {
  const legis = useSelector((state) => state.IsLegal);
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user.legal_entity) {
      navigate({ pathname: "/register" });
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 500,
      }}
    >
      <Empty description={t("No data")} />
    </div>
  );
}
export default withTranslation()(Home);
