import React, { useEffect, useState } from "react";
import "../login/login.scss";
import axios from "axios";
import url from "../../url.json";
import { withTranslation } from "react-i18next";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import headers from "../../config/headers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { tokenReducer } from "../../Redux/Reducers/Token/TokenReducer";
import { IsLegalReducer } from "../../Redux/Reducers/IsLegal/IsLegal";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import { Spin, Button } from "antd";
import { emailReducer } from "../../Redux/Reducers/Email/email";
function Forgot({ t }) {
  const dispatch = useDispatch();
  const [load, setload] = useState(false);
  const [error, setError] = useState();
  const [datas, setdatas] = useState();
  const navigate = useNavigate();
  const handleChange = (value) => {
    value.preventDefault();
    setload(true);
    const data = {
      email: value.target.email.value,
    };
    axios
      .post(url.url + "/mail", data, { headers: headers() })
      .then((res) => {
        toast.success(res.data.data.success);
        dispatch(emailReducer(value.target.email.value));
        setload(false);
        navigate("/createpassword");
        setError();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setload(false);

        setError(err.response.data.errors);
      });
  };
  const steps = [t("Enter email"), t("Create a password")];

  return (
    <div className="wrapper">
      <div className="forms_login">
        <div className="titless">{t("Forgot password")}</div>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={0} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className="ll">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Form className="forrr" onSubmit={handleChange}>
          <FormGroup>
            <Label className="lab" for="exampleUsername">
              {t("Email")}
            </Label>
            {!error?.username ? (
              <Input
                className="inpp"
                id="exampleUsername"
                name="email"
                placeholder={t("Email")}
                type="email"
              />
            ) : (
              <Input
                className="inpp invalid"
                id="exampleUsername"
                name="email"
                invalid
                placeholder={t("Email")}
                type="email"
              />
            )}
            {error ? (
              <i style={{ color: "red", fontSize: 14 }}>{error?.email}</i>
            ) : (
              ""
            )}
          </FormGroup>
          <Button
            className="w-100"
            type="primary"
            htmlType="submit"
            loading={load}
          >
            {t("Send code")}
          </Button>
        </Form>
      </div>
    </div>
  );
}
export default withTranslation()(Forgot);
