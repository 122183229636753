import React, { useEffect, useState } from "react";
import "../login/login.scss";
import axios from "axios";
import url from "../../url.json";
import { withTranslation } from "react-i18next";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import headers from "../../config/headers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { tokenReducer } from "../../Redux/Reducers/Token/TokenReducer";
import { IsLegalReducer } from "../../Redux/Reducers/IsLegal/IsLegal";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import { Spin, Button } from "antd";
function ForgotPass({ t }) {
  const [load, setload] = useState(false);
  const [error, setError] = useState();
  const email = useSelector((state) => state.email);
  const navigate = useNavigate();
  useEffect(() => {
    if (email.value == "") {
      navigate("/forgotpassword");
    }
  }, []);
  const handleChange = (value) => {
    value.preventDefault();
    setload(true);
    const data = {
      email: email.value,
      password: value.target.password.value,
      code: value.target.code.value,
      password_confirmation: value.target.password_confirmation.value,
    };
    axios
      .post(url.url + "/email/verified", data, { headers: headers() })
      .then((res) => {
        toast.success(res.data.data.success);
        setload(false);
        navigate("/login");
        setError();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setload(false);

        setError(err.response.data.errors);
      });
  };
  const steps = [t("Enter email"), t("Create a password")];

  return (
    <div className="wrapper">
      <div className="forms_login">
        <div className="titless">{t("Forgot password")}</div>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className="ll">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Form onSubmit={handleChange}>
          <span className="lab">
            {t("Code sent to email:")} {email.value}{" "}
          </span>
          <FormGroup>
            <Label className="lab" for="exampleUsername">
              {t("code_email")}
            </Label>
            {!error?.code ? (
              <Input
                className="inpp"
                id="exampleUsername"
                name="code"
                placeholder={t("code_email")}
              />
            ) : (
              <Input
                className="inpp invalid"
                id="exampleUsername"
                name="code"
                invalid
                placeholder={t("code_email")}
              />
            )}
            {error ? (
              <i style={{ color: "red", fontSize: 14 }}>
                {error?.code}
                <br />
              </i>
            ) : (
              ""
            )}
            <Label className="lab" for="exampleUsername">
              {t("New password")}
            </Label>

            <Input
              className="inpp"
              id="exampleUsername"
              name="password"
              placeholder={t("New password")}
              type="password"
            />

            <Label className="lab" for="exampleUsername">
              {t("Password confirmation")}
            </Label>
            <Input
              className="inpp"
              id="exampleUsername"
              name="password_confirmation"
              placeholder={t("Password confirmation")}
              type="password"
            />

            {error ? (
              <i style={{ color: "red", fontSize: 14 }}>
                {error?.password}
                <br />
              </i>
            ) : (
              ""
            )}
          </FormGroup>
          <Button
            className="w-100"
            type="primary"
            htmlType="submit"
            loading={load}
          >
            {t("Update password")}
          </Button>
        </Form>
      </div>
    </div>
  );
}
export default withTranslation()(ForgotPass);
