import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import dateFormat from "dateformat";
import "./styles.scss";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import axios from "axios";
import headers from "../../config/headers";
import url from "../../url.json";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Xronology({ t }) {
  const lan = useSelector((state) => state.langReducer);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [datas, setDatas] = React.useState();
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  React.useEffect(() => {
    axios
      .get(url.url + `/chronologies/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setDatas(res.data.data);
      });
  }, [lan]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div className="chr-holder">
      <div className="mb-5 wrap-head">
        <h5 className="app-title justify-content-center">
          {t("Chronological information")}
        </h5>
      </div>
      <Timeline mode="left">
        {datas?.map((res, i) =>
          res.key == "stages" ? (
            <Timeline.Item
              key={i}
              dot={
                <div className={res.date ? "cricleactives" : "cricleinactive"}>
                  {res.sort_number}
                </div>
              }
              label={
                <div className="chr-right flex-column">
                  <h5
                    className="chr-title"
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {res.stage.name}
                  </h5>
                  {res.date ? (
                    <p className="chr-text">
                      {" "}
                      {dateFormat(res.date, "dd.mm.yyyy")}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              }
            >
              <div className="flex-column">
                <h5>{res.department?.name} </h5>
                <p>{res.message}</p>
              </div>
            </Timeline.Item>
          ) : (
            <Timeline.Item
              key={i}
              label={
                <div className="flex-column">
                  {/* <h5 className="notics">{res.stage.name}</h5> */}
                  <p> {dateFormat(res.date, "dd.mm.yyyy")}</p>
                </div>
              }
            >
              <div className="flex-column notics">
                <h5 className="notics">{res.department.name} </h5>
                <p className="notics">{res.message}</p>
              </div>
            </Timeline.Item>
          )
        )}
      </Timeline>
    </div>
  );
  // return (
  //   <Box sx={{ maxWidth: 400 }}>
  //     <Stepper activeStep={activeStep} orientation="vertical">
  //       {steps.map((step, index) => (
  //         <Step key={step.label}>
  //           <StepLabel
  //             optional={
  //               index === 2 ? (
  //                 <Typography variant="caption">Last step</Typography>
  //               ) : null
  //             }
  //           >
  //             {step.label}
  //           </StepLabel>
  //           <StepContent>
  //             <Typography sx={{ mb: 4 }}>{step.description1}</Typography>

  //             <Typography>{step.description2}</Typography>

  //             {/* <Box sx={{ mb: 2 }}>
  //               <div>
  //                 <Button
  //                   variant="contained"
  //                   onClick={handleNext}
  //                   sx={{ mt: 1, mr: 1 }}
  //                 >
  //                   {index === steps.length - 1 ? "Finish" : "Continue"}
  //                 </Button>
  //                 <Button
  //                   disabled={index === 0}
  //                   onClick={handleBack}
  //                   sx={{ mt: 1, mr: 1 }}
  //                 >
  //                   Back
  //                 </Button>
  //               </div>
  //             </Box> */}
  //           </StepContent>
  //         </Step>
  //       ))}
  //     </Stepper>
  //     {activeStep === steps.length && (
  //       <Paper square elevation={0} sx={{ p: 3 }}>
  //         <Typography>All steps completed - you&apos;re finished</Typography>
  //         <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
  //           Reset
  //         </Button>
  //       </Paper>
  //     )}
  //   </Box>
  // );
}
export default withTranslation()(Xronology);
