import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import TextField from "@mui/material/TextField";
import "./form.scss";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import { MenuItem } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import dateFormat from "dateformat";
import { Button, Tooltip } from "antd";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import NativeSelect from "@mui/material/NativeSelect";
import { useSelector } from "react-redux";

const CustomDisableInput = styled(TextField)(() => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
  },

  ".MuiInputLabel-formControl.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
  },
}));
function AddForm(props) {
  const [rnd, setRnd] = useState();
  const [rnd1, setRnd1] = useState();
  const [rnd2, setRnd2] = useState();
  const [rnd3, setRnd3] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkstate, setCheckstate] = useState(true);
  const [checkstate1, setCheckstate1] = useState(true);
  const [checkstate2, setCheckstate2] = useState(true);
  const [checkstate3, setCheckstate3] = useState(true);
  const [rid, setRid] = useState();
  const [did, setDid] = useState();
  const [pid, setPid] = useState();
  const [mid, setMid] = useState();
  const user = useSelector((state) => state.profile?.data[0]);
  const [rname, setRname] = useState();
  const [radress, setRadress] = useState();
  const [rfax, setRfax] = useState();
  const [rphone, setRphone] = useState();
  const [dname, setDname] = useState();
  const [trade_name, settrade_name] = useState();
  const [structure, setstructure] = useState();
  const [drug_figure, setdrug_figure] = useState();
  const [drug_type, setDrug_type] = useState();
  const [drugTypes, setDrugTypes] = useState();
  const [usage, setusage] = useState();
  const [patent, setpatent] = useState();
  const [pname, setPname] = useState();
  const [padress, setPadress] = useState();
  const [pfax, setPfax] = useState();
  const [pphone, setPphone] = useState();
  const [mname, setMname] = useState();
  const [madress, setMadress] = useState();
  const [mfax, setMfax] = useState();
  const [mphone, setMphone] = useState();
  const [err, setErr] = useState();
  const [rdata, setRdata] = useState([]);
  const [ddata, setDdata] = useState([]);
  const [pdata, setPdata] = useState([]);
  const [mdata, setMdata] = useState([]);
  const [drugd, setDrugd] = useState([]);
  const [repd, setrepd] = useState([]);
  const [drugb, setDrugb] = useState(false);
  const [repb, setrepb] = useState(false);
  const [prepd, setprepd] = useState([]);
  const [prepb, setprepb] = useState(false);
  const [mand, setmand] = useState([]);
  const [manb, setmanb] = useState(false);
  const [country, setCountry] = useState();
  const [drugfig, setDrugFig] = useState();
  const refdrug = useRef(null);
  const onChange = (e) => {
    setRnd(Math.random());
    if (checkstate) {
      setCheckstate(false);
    } else setCheckstate(true);
    setRid();
    setRname();
    setrepb(!repb);

    for (var member in repd) delete repd[member];
  };
  const onChange1 = (e) => {
    setRnd1(Math.random());
    if (checkstate1) {
      setCheckstate2(true);
      setCheckstate3(true);
      setCheckstate1(false);
      setDrugb(true);
      setmanb(true);
      setprepb(true);
      delete drugd["preparer"];
      for (var member in prepd) delete prepd[member];
      delete drugd["manufacturer"];
      for (var member in mand) delete mand[member];
    } else {
      setCheckstate1(true);
      setprepb(false);
      setDrugb(false);
      setmanb(false);
      setMphone();
      setPphone();
    }
    setErr();
    setDid();
    setDname();
    for (var member in drugd) delete drugd[member];
  };
  const onChange2 = (e) => {
    setRnd2(Math.random());
    if (checkstate2) {
      setCheckstate2(false);
    } else setCheckstate2(true);
    setPid();
    setPname();
    setprepb(!prepb);
    setPphone();
    delete drugd["preparer"];
    for (var member in prepd) delete prepd[member];

    setErr();
  };
  const onChange3 = (e) => {
    setRnd3(Math.random());
    if (checkstate3) {
      setCheckstate3(false);
    } else {
      setCheckstate3(true);
      setMid();
      setMphone();
    }
    setErr();

    setMname();
    setmanb(!manb);
    delete drugd["manufacturer"];
    for (var member in mand) delete mand[member];
  };
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios.get(url.url + "/drug/figure", { headers: headers() }).then((res) => {
      setDrugFig(res.data.data);
    });
    axios.get(url.url + "/drug-type", { headers: headers() }).then((res) => {
      setDrugTypes(res.data.data);
    });
    axios
      .get(url.url + "/entity?type=representative", { headers: headers() })
      .then((res) => {
        setRdata(res.data.data);
      });
    axios
      .get(url.url + "/entity?type=preparer", { headers: headers() })
      .then((res) => {
        setPdata(res.data.data);
      });
    axios
      .get(url.url + "/entity?type=manufacturer", { headers: headers() })
      .then((res) => {
        setMdata(res.data.data);
      });
    axios.get(url.url + "/drug", { headers: headers() }).then((res) => {
      setDdata(res.data.data);
    });
    axios
      .get(url.url + "/country", { headers: headers() })
      .then((res) => {
        setCountry(res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        toast.error(err.response.data);
      });
  }, []);
  useEffect(() => {
    if (did) {
      axios
        .get(url.url + `/drug/${did}`, { headers: headers() })
        .then((res) => {
          setDrugd(res.data.data);
          setMphone(res.data.data.manufacturer.phone);
          setPphone(res.data.data.preparer.phone);
        });
      setDrugb(true);
    }
  }, [did]);
  useEffect(() => {
    if (rid) {
      axios
        .get(url.url + `/entity/${rid}`, { headers: headers() })
        .then((res) => {
          setrepd(res.data.data);
        });
      setrepb(true);
    }
  }, [rid]);
  useEffect(() => {
    if (pid) {
      axios
        .get(url.url + `/entity/${pid}`, { headers: headers() })
        .then((res) => {
          setprepd(res.data.data);
          setPphone(res.data.data.phone);
        });
      setprepb(true);
    }
  }, [pid]);
  useEffect(() => {
    if (mid) {
      axios
        .get(url.url + `/entity/${mid}`, { headers: headers() })
        .then((res) => {
          setmand(res.data.data);
          setMphone(res.data.data.phone);
        });
      setmanb(true);
    }
  }, [mid]);
  const handleSave = () => {
    const data = {
      applicant: user?.data.legal_entity?.name,
      drug_type_id: drug_type,
    };
    if (rid) {
      data.representative_id = rid;
    } else {
      data.representative = {
        name: rname,
        address: radress,
        fax: rfax,
        phone: rphone,
      };
    }
    if (did) {
      data.drug_id = did;
    } else {
      data.drug = {
        name: dname,
        trade_name: trade_name,
        structure: structure,
        drug_figure_id: document.getElementById("examplefigure").value,
        usage: usage,
        patent: patent,
        country_id: document.getElementById("examplecountry").value,
      };
      if (pid) {
        data.drug.preparer_id = pid;
      } else {
        data.drug.preparer = {
          name: pname,
          address: padress,
          fax: pfax,
          phone: pphone,
        };
      }
      if (mid) {
        data.drug.manufacturer_id = mid;
      } else {
        data.drug.manufacturer = {
          name: mname,
          address: madress,
          fax: mfax,
          phone: mphone,
        };
      }
    }

    axios
      .post(url.url + "/application", data, { headers: headers() })
      .then((res) => {
        localStorage.setItem("applic", res.data.data.id);
        if (res.status == 201) {
          setErr();
          toast.success(t("Success!"));
          props.handlenext(res.data.data.id);
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setErr(err.response.data.errors);
      });
  };
  if (user)
    return (
      <div>
        <div className="form-body-wrapp">
          <div className="frm-con" style={{ width: "100%" }}>
            <FormControl fullWidth>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-4">{t("Applicant_Information")}</h5>
              </div>
              <CustomDisableInput
                disabled
                defaultValue={user?.data.legal_entity?.name}
                className="appp-inp text-field mb-2 mt-2"
                fullWidth
                label={t("Applicant")}
                variant="standard"
                type="text"
              />
              <div className="check-lable">
                <Switch defaultChecked={false} onChange={onChange} />
                <span> {t("choose_from")} </span>
                <Tooltip
                  className="ml-2"
                  placement="right"
                  title={t("last_info")}
                  color={"#109cf1"}
                >
                  <InfoCircleOutlined className="icon-inf" />
                </Tooltip>
              </div>
              <div key={rnd}>
                {checkstate ? (
                  err?.["representative.name"] ? (
                    <CustomDisableInput
                      error
                      helperText={err?.["representative.name"]}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Representative of the applicant")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRname(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Representative of the applicant")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRname(e.target.value)}
                    />
                  )
                ) : (
                  <CustomDisableInput
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    select
                    label={t("Representative of the applicant")}
                    helperText=""
                    variant="standard"
                    onChange={(e) => setRid(e.target.value)}
                    defaultValue={rdata[0]?.name}
                  >
                    {rdata?.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option.id}
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        style={{ backgroundColor: "transparent" }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomDisableInput>
                )}
                {err?.["representative.address"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["representative.address"]}
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Address")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setRadress(e.target.value)}
                  />
                ) : (
                  <div key={repd?.address + "repd-Address"}>
                    <CustomDisableInput
                      disabled={repb}
                      defaultValue={repd?.address}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRadress(e.target.value)}
                    />
                  </div>
                )}
                {err?.["representative.fax"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["representative.fax"]}
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Fax address")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setRfax(e.target.value)}
                  />
                ) : (
                  <div key={repd?.fax + "repd-Fax address"}>
                    <CustomDisableInput
                      disabled={repb}
                      defaultValue={repd?.fax}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Fax address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRfax(e.target.value)}
                    />
                  </div>
                )}

                <div key={repd?.phone + "Phone number"}>
                  {/* <CustomDisableInput
                      disabled={repb}
                      defaultValue={repd?.phone}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Phone number")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRphone(e.target.value)}
                    /> */}
                  <span
                    className="titleee"
                    style={drugb ? { color: "#000" } : {}}
                  >
                    {t("Phone number")}
                  </span>
                  <PhoneInput
                    disabled={repb}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    className={
                      err?.["representative.phone"]
                        ? "inpp-countryy err-phone"
                        : "inpp-countryy"
                    }
                    international
                    defaultCountry="UZ"
                    value={repd?.phone}
                    onChange={(e) => setRphone(e)}
                  />
                  {err?.["representative.phone"] ? (
                    <div className="invalid-phone">
                      {err?.["representative.phone"]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 50 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-4">{t("Drug Information")}</h5>
              </div>
              {err?.["drug_type_id"] ? (
                <CustomDisableInput
                  error
                  className="appp-inp text-field mb-2 mt-2"
                  fullWidth
                  select
                  label={t("Drug_type")}
                  onChange={(e) => setDrug_type(e.target.value)}
                  helperText={err?.["drug_type_id"]}
                  variant="standard"
                >
                  {drugTypes?.map((option, i) => (
                    <MenuItem
                      key={i}
                      value={option.id}
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          backgroundColor: "red",
                        },
                      }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </CustomDisableInput>
              ) : (
                <CustomDisableInput
                  className="appp-inp text-field mb-2 mt-2"
                  fullWidth
                  select
                  label={t("Drug_type")}
                  onChange={(e) => setDrug_type(e.target.value)}
                  helperText=""
                  variant="standard"
                >
                  {drugTypes?.map((option, i) => (
                    <MenuItem
                      key={i}
                      value={option.id}
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          backgroundColor: "red",
                        },
                      }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </CustomDisableInput>
              )}

              <div className="check-lable">
                <Switch defaultChecked={false} onChange={onChange1} />
                <span> {t("choose_from")} </span>

                <Tooltip
                  className="ml-2"
                  placement="right"
                  title={t("last_info")}
                  color={"#109cf1"}
                >
                  <InfoCircleOutlined className="icon-inf" />
                </Tooltip>
              </div>
              <div key={rnd1}>
                {checkstate1 ? (
                  err?.["drug.name"] ? (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.name"]}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Drug")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setDname(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Drug")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setDname(e.target.value)}
                    />
                  )
                ) : (
                  <CustomDisableInput
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    select
                    label={t("Drug")}
                    onChange={(e) => setDid(e.target.value)}
                    helperText=""
                    variant="standard"
                    defaultValue={ddata[0]?.name}
                  >
                    {ddata?.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option.id}
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        style={{ backgroundColor: "transparent" }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomDisableInput>
                )}
                {err?.["drug.trade_name"] ? (
                  <CustomDisableInput
                    className="appp-inp text-field mb-2 mt-2"
                    error
                    helperText={err?.["drug.trade_name"]}
                    fullWidth
                    label={t("Trade name")}
                    variant="standard"
                    type="text"
                    onChange={(e) => settrade_name(e.target.value)}
                  />
                ) : (
                  <div key={drugd?.trade_name}>
                    <CustomDisableInput
                      disabled={drugb}
                      defaultValue={drugd?.trade_name}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Trade name")}
                      variant="standard"
                      type="text"
                      onChange={(e) => settrade_name(e.target.value)}
                    />
                  </div>
                )}
                {err?.["drug.structure"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["drug.structure"]}
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Content")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setstructure(e.target.value)}
                  />
                ) : (
                  <div key={drugd?.structure + "Content"}>
                    <CustomDisableInput
                      disabled={drugb}
                      defaultValue={drugd?.structure}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Content")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setstructure(e.target.value)}
                    />
                  </div>
                )}

                <div key={drugd?.drug_figure + "Drug form"}>
                  <span
                    className="titleee"
                    style={drugb ? { color: "#000" } : {}}
                  >
                    {t("Drug form")}
                  </span>
                  <FormControl
                    disabled={drugb}
                    fullWidth
                    className="appp-inp text-field mb-2 mt-2"
                  >
                    <NativeSelect
                      style={drugb ? { color: "#000" } : {}}
                      inputProps={{
                        id: "examplefigure",
                      }}
                      defaultValue={`${drugd?.drug_figure?.id}`}
                    >
                      <option value=""></option>
                      {drugfig?.map((res, i) => (
                        <option key={i} value={res.id}>
                          {res.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
                {err?.["drug.usage"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["drug.usage"]}
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Applicat")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setusage(e.target.value)}
                  />
                ) : (
                  <div key={drugd?.usage + "Applicat"}>
                    <CustomDisableInput
                      ref={refdrug}
                      disabled={drugb}
                      defaultValue={drugd?.usage}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Applicat")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setusage(e.target.value)}
                    />
                  </div>
                )}
                {err?.["drug.patent"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["drug.patent"]}
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Patent")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setpatent(e.target.value)}
                  />
                ) : (
                  <div key={drugd?.patent + "Patent"}>
                    <CustomDisableInput
                      disabled={drugb}
                      defaultValue={drugd?.patent}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Patent")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setpatent(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  <span
                    className="titleee"
                    style={drugb ? { color: "#000" } : {}}
                  >
                    {t("Country")}
                  </span>
                  <FormControl
                    disabled={drugb}
                    fullWidth
                    className="appp-inp text-field mb-2 mt-2"
                  >
                    <NativeSelect
                      style={drugb ? { color: "#000" } : {}}
                      inputProps={{
                        id: "examplecountry",
                      }}
                      defaultValue={drugd?.country?.id}
                    >
                      {country?.data.map((res, i) => (
                        <option key={i} value={res.id}>
                          {res.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
              {checkstate1 ? (
                <div className="check-lable">
                  <Switch
                    disabled={drugb}
                    defaultChecked={false}
                    onChange={onChange2}
                  />
                  <span> {t("choose_from")} </span>
                  <Tooltip
                    className="ml-2"
                    placement="right"
                    title={t("last_info")}
                    color={"#109cf1"}
                  >
                    <InfoCircleOutlined className="icon-inf" />
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
              <div key={rnd2}>
                {checkstate2 ? (
                  err?.["drug.preparer.name"] ? (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.preparer.name"]}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Preparer")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setPname(e.target.value)}
                    />
                  ) : (
                    <div
                      key={
                        drugd.preparer
                          ? drugd.preparer?.name + "Preparer"
                          : prepd?.name + "Preparer"
                      }
                    >
                      <CustomDisableInput
                        disabled={drugd.preparer ? drugb : prepb}
                        defaultValue={
                          drugd.preparer ? drugd.preparer?.name : prepd?.name
                        }
                        className="appp-inp text-field mb-2 mt-2"
                        fullWidth
                        label={t("Preparer")}
                        variant="standard"
                        type="text"
                        onChange={(e) => setPname(e.target.value)}
                      />
                    </div>
                  )
                ) : (
                  <CustomDisableInput
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    select
                    label={t("Preparer")}
                    onChange={(e) => setPid(e.target.value)}
                    helperText=""
                    variant="standard"
                    defaultValue={pdata[0]?.name}
                  >
                    {pdata?.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option.id}
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        style={{ backgroundColor: "transparent" }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomDisableInput>
                )}
                <div
                  key={
                    drugd.preparer
                      ? drugd.preparer?.address + "preparerAddress"
                      : prepd?.address + "preparerAddress"
                  }
                >
                  <CustomDisableInput
                    disabled={drugd.preparer ? drugb : prepb}
                    defaultValue={
                      drugd.preparer ? drugd.preparer?.address : prepd?.address
                    }
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Address")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setPadress(e.target.value)}
                  />
                </div>
                <div
                  key={
                    drugd.preparer
                      ? drugd.preparer?.fax + "preparerFax address"
                      : prepd?.fax + "preparerFax address"
                  }
                >
                  <CustomDisableInput
                    disabled={drugd.preparer ? drugb : prepb}
                    defaultValue={
                      drugd.preparer ? drugd.preparer?.fax : prepd?.fax
                    }
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Fax address")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setPfax(e.target.value)}
                  />
                </div>
                <div
                  key={
                    drugd.preparer
                      ? drugd.preparer?.phone + "preparerPhone number"
                      : prepd?.phone + "preparerPhone number"
                  }
                >
                  <span
                    className="titleee"
                    style={drugb ? { color: "#000" } : {}}
                  >
                    {t("Phone number")}
                  </span>
                  <PhoneInput
                    disabled={drugd.preparer ? drugb : prepb}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    className={
                      err?.["drug.preparer.phone"]
                        ? "inpp-countryy err-phone"
                        : "inpp-countryy"
                    }
                    international
                    defaultCountry="UZ"
                    value={pphone}
                    onChange={(e) => setPphone(e)}
                  />
                  {err?.["drug.preparer.phone"] ? (
                    <div className="invalid-phone">
                      {err?.["drug.preparer.phone"]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {checkstate1 ? (
                <div className="check-lable">
                  <Switch
                    disabled={drugb}
                    defaultChecked={false}
                    onChange={onChange3}
                  />
                  <span> {t("choose_from")} </span>
                  <Tooltip
                    className="ml-2"
                    placement="right"
                    title={t("last_info")}
                    color={"#109cf1"}
                  >
                    <InfoCircleOutlined className="icon-inf" />
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
              <div key={rnd3}>
                {checkstate3 ? (
                  err?.["drug.manufacturer.name"] ? (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.manufacturer.name"]}
                      className="appp-inp text-field mb-2 mt-2"
                      fullWidth
                      label={t("Manufacturer")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setMname(e.target.value)}
                    />
                  ) : (
                    <div
                      key={
                        drugd.manufacturer
                          ? drugd.manufacturer?.name + "Manufacturer"
                          : mand?.name + "Manufacturer"
                      }
                    >
                      <CustomDisableInput
                        disabled={drugd.manufacturer ? drugb : manb}
                        defaultValue={
                          drugd.manufacturer
                            ? drugd.manufacturer?.name
                            : mand?.name
                        }
                        className="appp-inp text-field mb-2 mt-2"
                        fullWidth
                        label={t("Manufacturer")}
                        variant="standard"
                        type="text"
                        onChange={(e) => setMname(e.target.value)}
                      />
                    </div>
                  )
                ) : (
                  <CustomDisableInput
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    select
                    label={t("Manufacturer")}
                    onChange={(e) => setMid(e.target.value)}
                    helperText=""
                    variant="standard"
                    defaultValue={mdata[1]?.name}
                  >
                    {mdata?.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option.id}
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        style={{ backgroundColor: "transparent" }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomDisableInput>
                )}
                <div
                  key={
                    drugd?.manufacturer
                      ? drugd?.manufacturer?.address + "Address"
                      : mand?.address + "Address"
                  }
                >
                  <CustomDisableInput
                    disabled={drugd.manufacturer ? drugb : manb}
                    defaultValue={
                      drugd.manufacturer
                        ? drugd.manufacturer?.address
                        : mand?.address
                    }
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Address")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setMadress(e.target.value)}
                  />
                </div>
                <div
                  key={
                    drugd.manufacturer
                      ? drugd.manufacturer?.fax + "Fax address"
                      : mand?.fax + "Fax address"
                  }
                >
                  <CustomDisableInput
                    disabled={drugd.manufacturer ? drugb : manb}
                    defaultValue={
                      drugd.manufacturer ? drugd.manufacturer?.fax : mand?.fax
                    }
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    label={t("Fax address")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setMfax(e.target.value)}
                  />
                </div>

                <span
                  className="titleee"
                  style={drugb ? { color: "#000" } : {}}
                >
                  {t("Phone number")}
                </span>
                <PhoneInput
                  disabled={drugd.manufacturer ? drugb : manb}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className={
                    err?.["drug.manufacturer.phone"]
                      ? "inpp-countryy err-phone"
                      : "inpp-countryy"
                  }
                  international
                  defaultCountry="UZ"
                  value={mphone}
                  onChange={(e) => setMphone(e)}
                />
                {err?.["drug.manufacturer.phone"] ? (
                  <div className="invalid-phone">
                    {err?.["drug.manufacturer.phone"]}
                  </div>
                ) : (
                  ""
                )}

                <div
                  className="info-header__wrapper date-form text-right"
                  style={{ justifyContent: "flex-end" }}
                >
                  <i className="info-header__icon bx bx-calendar date-icon-form"></i>
                  <span className="info-header__text ">
                    {dateFormat(new Date(), "dd.mm.yyyy")}
                  </span>
                </div>
              </div>
            </FormControl>
          </div>
        </div>

        <div className="row justify-content-end mt-3 mr-1">
          <Button
            className="mr-2"
            type="primary"
            size={20}
            onClick={() => navigate("/account/applications")}
          >
            {t("Cancel")}
          </Button>
          <div>
            <Button type="primary" size={20} onClick={handleSave}>
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>
    );
}

export default withTranslation()(AddForm);
