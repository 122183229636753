import React, { useEffect, useState } from "react";
import "../List/List.scss";
import url from "../../url.json";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../assets/icon/delete.svg";
import Eye from "../../assets/icon/eye.svg";
import { toast } from "react-toastify";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import headers from "../../config/headers";
import dateFormat from "dateformat";
import { Empty } from "antd";
import "antd/dist/antd.css";
import { Button, Dropdown, Menu } from "antd";
import Edit from "../Icons/Edit";
import Dowolland from "../Icons/Dowolland";
import { Alert, Spin } from "antd";
import { Table } from "reactstrap";
import ModalPayDel from "../Modal/ModalPayDel";
import { modalpayReducer } from "../../Redux/Reducers/Modal/modalpay";
import { modalpaydelReducer } from "../../Redux/Reducers/Modal/modalpaydel";

function PayList({ t }) {
  const modalpay = useSelector((state) => state.modalpay);
  const modalpaydel = useSelector((state) => state.modalpaydel);

  const [searchParams, setSearchParams] = useSearchParams();
  const [shows, setShows] = useState(false);
  const [datares, setDatares] = useState();

  const handleCancel = () => {
    setShows(false);
  };

  const menu = (res) => {
    if (res?.verified_at == null)
      return (
        <Menu
          items={[
            {
              label: t("Delete"),
              key: "1",
              icon: <img className="btn-icon" src={Delete} alt="delete icon" />,
              onClick: () => {
                setShows(true);
                setDatares(res);
              },
            },

            {
              label: t("Dowolland"),
              key: "2",
              icon: <Dowolland width={18} height={18} />,
              onClick: () => {
                window.location.href = res?.file;
              },
            },
          ]}
        />
      );
    else
      return (
        <Menu
          items={[
            {
              label: t("Dowolland"),
              key: "1",
              icon: <Dowolland width={18} height={18} />,
              onClick: () => {
                window.location.href = res?.file;
              },
            },
          ]}
        />
      );
  };
  const navigate = useNavigate();
  const [datas, setData] = useState();
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + `/payment?contract=${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [shows]);
  return (
    <div className="pay_list_wrap">
      <ModalPayDel show={shows} handleCancel={handleCancel} res={datares} />

      {datas ? (
        <Table
          responsive="sm"
          style={{ backgroundColor: "white", borderRadius: 15 }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>{t("File")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Status")}</th>
              <th>{t("Created at")}</th>
              <th>{t("Verified at")}</th>
              <th>{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {datas?.map((res, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{res.file?.split("/")[res.file?.split("/").length - 1]}</td>
                <td>{res.amount}</td>
                <td>
                  <div
                    className={
                      res.status == 1
                        ? `btn out btn-outline-primary`
                        : `btn out btn-outline-secondary`
                    }
                  >
                    {res.status == 1 ? t("paid") : t("unpaid")}
                  </div>
                </td>
                <td> {dateFormat(res.created_at, "dd.mm.yyyy")}</td>
                <td>
                  {res.verified_at !== null
                    ? dateFormat(res.verified_at, "dd.mm.yyyy")
                    : "--.--.----"}
                </td>

                <td>
                  <Dropdown
                    overlay={menu(res)}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <i className="header-list__icon bx bx-dots-vertical-rounded"></i>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 500,
            backgroundColor: "white",
            borderRadius: 15,
          }}
        >
          <Spin tip="Loading...">
            <Empty description={t("No data")} />
          </Spin>
        </div>
      )}
    </div>
  );
}
export default withTranslation()(PayList);
