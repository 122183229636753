import React, { useEffect, useState } from "react";
import "./header.scss";
import { NavLink, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import url from "../../url.json";
import SelectLan from "./SelectLan";
import { withTranslation } from "react-i18next";
import AccountMenu from "./AccountMenu";
import Menu from "../../assets/icon/menu.svg";
import axios from "axios";
import headers from "../../config/headers";
function Header({ t }) {
  const [datenow, setdatenow] = useState(new Date());
  const [Setting, setSetting] = useState();
  const [open, setOpen] = useState("");

  const handelClick = () => {
    if (open == "") {
      setOpen("opened");
    } else setOpen("");
  };
  useEffect(() => {
    setInterval(() => {
      setdatenow(new Date());
    }, 1000);
  }, []);

  const navigate = useNavigate();
  const scrollToID = async (id) => {
    await navigate("/");
    window.location.href = `#${id}`;
  };

  return (
    <header className="header">
      <div className="header__inner containerr">
        <div className="header__info info-header">
          <div className="info-header__wrapper">
            <i className="info-header__icon bx bx-calendar"></i>
            <span className="info-header__text">
              {dateFormat(datenow, "dd.mm.yyyy")}
            </span>
          </div>
          <div className="info-header__wrapper">
            <i className="info-header__icon bx bx-time"></i>
            <span className="info-header__text">
              {dateFormat(datenow, "HH:MM:ss")}
            </span>
          </div>
        </div>
        {/* Navvvvvvvv */}
        <div onClick={handelClick} className={`header-nav__ww ${open}`}>
          <ul className="header__nav nav">
            <li className="nav__item">
              <NavLink
                className="nav__link"
                to={{
                  pathname: "/",
                }}
              >
                {t("Home")}
              </NavLink>
            </li>
            <li className="nav__item" onClick={() => scrollToID("about")}>
              <a className={"nav__link"} href="#about">
                {t("About")}
              </a>
            </li>
            <li className="nav__item" onClick={() => scrollToID("about")}>
              <a className={"nav__link"} href="#blog">
                {t("News")}
              </a>
            </li>
            <li className="nav__item" onClick={() => scrollToID("contact")}>
              <a className={"nav__link"} href="#contact">
                {t("Contact")}
              </a>
            </li>
            <li className="nav__item">
              <NavLink
                className="nav__link"
                to={{
                  pathname: "/regulations",
                }}
              >
                {t("Regulations")}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                className="nav__link"
                to={{
                  pathname: "/reestr",
                }}
              >
                {t("Reestr")}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                className="nav__link"
                to={{
                  pathname: "/faq",
                }}
              >
                {t("FAQ")}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="header__detail detail-header">
          <ul className="header__list header-list">
            <li className="header-list__item">
              <SelectLan />
            </li>
            <li className="header-list__item">
              <AccountMenu />
            </li>
            <li className="header-list__item">
              <button onClick={handelClick} className="menu-open">
                <img src={Menu} alt="" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
export default withTranslation()(Header);
