import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import "../../pages/layout/layout.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { classReducer } from "../../Redux/Reducers/openClassSidebar";
import { useSelector } from "react-redux";
import url from "../../url.json";
import { withTranslation } from "react-i18next";
import Logo from "../../assets/icon/logo.png";
import Avatar from "@mui/material/Avatar";
import { toast } from "react-toastify";
import Modal from "react-modal";
import axios from "axios";
import { tokenReducer } from "../../Redux/Reducers/Token/TokenReducer";
import ModalLogout from "../Modal/Logout/ModalLogout";
import Badge from "../../components/badge/badge";
import { Skeleton, Tooltip, Space } from "antd";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function Sidebar({ t, i18n }) {
  const [show, setShow] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function cancelModal() {
    setIsOpen(false);
  }
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [Setting, setSetting] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile?.data);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    setSetting(user[0]?.data);
  }, [user]);
  const handeclick = () => {
    if (active) {
      dispatch(classReducer("opened"));
      setActive(false);
    } else {
      dispatch(classReducer(""));
      setActive(true);
    }
  };
  const handleClickLog = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axios
      .post(url.url + "/logout")
      .then((res) => {
        setIsOpen(false);
        dispatch(tokenReducer(null));
        toast.success(t("Success!"));
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  return (
    <div className="sidebar">
      <div className="sidebar-top">
        <div className="sidebar__head head-sidebar">
          <img
            className="landing-header__logo head-sidebar__title-open"
            src={Logo}
            alt="site logo"
            width={45}
            height={45}
          />
          <NavLink
            className="sidebar-list__texttt"
            style={{
              color: "#109CF1",
              marginLeft: 10,
              fontSize: 20,
              textDecoration: "none",
            }}
            to="/"
          >
            VIS NEFRIT
          </NavLink>
        </div>
        <div className="sidebar__main sidebar-main">
          {user[0]?.data.legal_entity ? (
            <div
              className="sidebar-main__entrance sidebar-entrance"
              onClick={() => navigate("/account/profile")}
            >
              <div className="sidebar-entrance__logo">
                {Setting?.avatar ? (
                  <img
                    className="sidebar-entrance__img"
                    src={Setting ? Setting?.avatar : ""}
                  />
                ) : (
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    style={{ textTransform: "uppercase" }}
                  >
                    {Setting?.username.charAt(0)}
                  </Avatar>
                )}
              </div>
              <div className="sidebar-entrance__detail">
                <h3 className="sidebar-entrance__detail-title">
                  {Setting?.username}
                </h3>
                <span className="sidebar-entrance__detail-text">
                  {Setting?.legal_entity?.name}
                </span>
              </div>
              {user[0]?.data.legal_entity?.blocked_at === null ? (
                user[0]?.data.legal_entity?.verified_at ? (
                  <Tooltip
                    className="ml-2"
                    placement="right"
                    title={t("success_info")}
                    color={"#109cf1"}
                  >
                    <i className="tick-icon bx bxs-check-circle "></i>
                  </Tooltip>
                ) : (
                  <Tooltip
                    className="ml-2"
                    placement="right"
                    title={t("warning_info")}
                    color={"#109cf1"}
                  >
                    <i className="x-icon  bx bxs-x-circle"></i>
                  </Tooltip>
                )
              ) : (
                <Tooltip
                  className="ml-2"
                  placement="right"
                  title={t("blog_info")}
                  color={"#109cf1"}
                >
                  <i className="x-icon x-danger bx bxs-x-circle"> </i>
                </Tooltip>
              )}
            </div>
          ) : (
            <div className="sidebar-main__entrance sidebar-entrance">
              {" "}
              <Space>
                <Skeleton.Avatar
                  active={true}
                  size={"large"}
                  shape={"circle"}
                />
                <Skeleton.Input active={true} size={"small"} />
              </Space>
            </div>
          )}
          <ul className="sidebar-main__list sidebar-list">
            <li className="sidebar-list__item">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "sidebar-list__link active "
                    : "sidebar-list__link"
                }
                to="/account/applicationsadd"
              >
                <i className="sidebar-list__icon bx bx-customize"></i>
                <span className="sidebar-list__text">
                  {t("Add application")}
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list__item">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "sidebar-list__link active "
                    : "sidebar-list__link"
                }
                to="/account/applications"
              >
                <i className="sidebar-list__icon bx bxs-report"></i>
                <span className="sidebar-list__text">
                  {t("Application list")}
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list__item">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "sidebar-list__link active "
                    : "sidebar-list__link"
                }
                to="/account/contract"
              >
                <i className="sidebar-list__icon bx bxs-file"></i>
                <span className="sidebar-list__text">
                  {t("Contracts list")}
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list__item">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "sidebar-list__link active "
                    : "sidebar-list__link"
                }
                to="/account/mycertificates"
              >
                <i className="sidebar-list__icon bx bxs-file"></i>
                <span className="sidebar-list__text">
                  {t("My Sertificats")}
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list__item bild">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "sidebar-list__link active "
                    : "sidebar-list__link"
                }
                to={{ pathname: "/account/notic" }}
              >
                <i className="sidebar-list__icon bx bxs-bell"></i>
                <span className="sidebar-list__text">{t("Settings")}</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar-bottom">
        <Badge />
      </div>
      <Modal
        //! modal codes
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={cancelModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modalmain">
          <div className="modalmain__text">{t("Do you want to log out?")}</div>
          <div className="modalmain__btn">
            <button
              className="landing-header__btn signin"
              type="button"
              onClick={cancelModal}
            >
              {t("Cancel")}
            </button>
            <button
              className="landing-header__btn signup"
              type="button"
              onClick={handleClickLog}
            >
              {t("Logout")}
            </button>
          </div>
        </div>
      </Modal>
      <ModalLogout onClose={() => setShow(false)} show={show} />
    </div>
  );
}
export default withTranslation()(Sidebar);
