import React, { useState } from "react";
import "./styles.scss";
import PayList from "./PayList";
import ModalPay from "../Modal/ModalPay";
import { modalpayReducer } from "../../Redux/Reducers/Modal/modalpay";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Steps, Alert, Empty } from "antd";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
const { Step } = Steps;
function Payshow({ t }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const showModal = () => {
    dispatch(modalpayReducer(true));
  };
  const openFile = () => {
    fileRef.current.click();
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  if (searchParams.get("con_id"))
    return (
      <>
        <ModalPay
          file={file}
          con_id={searchParams.get("con_id")}
          id={searchParams.get("id")}
        />

        <input
          type="file"
          id="file"
          ref={fileRef}
          onChange={handleChange}
          style={{ display: "none" }}
        />

        <div className="pay-list">
          <PayList />
        </div>
      </>
    );
  else
    return (
      <div className="cards-body">
        <div className="cards justify-content-center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 500,
            }}
          >
            <Empty
              style={{ fontSize: 16, fontStyle: "italic" }}
              description={t(
                "Ushbu ariza bo'yicha to'lov shartnoma biriktirilmagan.Shartnoma biriktirilishini kuting. "
              )}
            />
          </div>
          ;
        </div>
      </div>
    );
}
export default withTranslation()(Payshow);
