import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./styles.scss";
import { withTranslation } from "react-i18next";
import Friststep from "./Friststep";
import { Helmet } from "react-helmet-async";
function ChekoutPage({ t }) {
  const steps = [t("First step"), t("Last step")];
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Sign up")}</title>
      </Helmet>
      <div className="forms">
        <div className="title" style={{ textTransform: "none" }}>
          {t("Sign up")}
        </div>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={0} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className="ll">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Friststep />
      </div>
    </div>
  );
}
export default withTranslation()(ChekoutPage);
