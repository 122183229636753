import React, { useEffect, useRef, useState } from "react";
import "./chat.scss";
import {
  PaperClipOutlined,
  SendOutlined,
  SmileOutlined,
  FileOutlined,
} from "@ant-design/icons";
import Avatar from "@mui/material/Avatar";

import { Input, Form, Alert } from "antd";
import { Button } from "@mui/material";
import axios from "axios";
import headers from "../../config/headers";
import url from "../../url.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import dateFormat from "dateformat";
import ModalChat from "../Modal/ModalChat";
import { withTranslation } from "react-i18next";
function Chat({ t }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [datas, setDatas] = useState();
  const formRef = React.createRef();
  const [resp, setRes] = useState();
  const [mess, setMess] = useState();
  const [showse, setShowse] = useState(false);

  const [userid, setUserid] = useState(
    JSON.parse(localStorage.getItem("user")).id
  );
  const [oldate, setoldate] = useState();
  const onFinish = (values) => {
    setShowse(false);
    setMess(values.message);
    const data = {
      application_id: searchParams.get("id"),

      message: values.message,
    };
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    if (values.message)
      axios
        .post(url.url + "/message", data, { headers: headers() })
        .then((res) => {
          setRes(res.data);
        });
    formRef.current.resetFields();
  };
  useEffect(() => {
    setUserid(JSON.parse(localStorage.getItem("user")).id);
    axios
      .get(url.url + `/chat?application_id=${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setDatas(res.data.data);
      });
    window.scrollTo(0, document.getElementById("datas").scrollHeight);
  }, [resp, showse]);
  function Messageany(res) {
    return (
      <div className="chat-message-left pb-4">
        <div>
          {!res.user.avatar ? (
            <Avatar sx={{ width: 32, height: 32 }}>
              {res.user.username.charAt(0)}
            </Avatar>
          ) : (
            <img src={res.user.avatar} className="av" width="40" height="40" />
          )}
          <div className="text-muted small text-nowrap mt-2">
            {dateFormat(res.created_at, "HH:MM")}
            <br />

            {dateFormat(res.created_at, "dd.mm.yyyy")}
          </div>
        </div>
        <div
          className="flex-shrink-1 bg-light rounded py-2 px-3 ml-3 "
          style={{ maxWidth: "50%" }}
        >
          <div className="font-weight-bold mb-1">{res.user.username}</div>
          {res?.file ? (
            <div className="row p-2">
              <div className="pr-2">
                <FileOutlined style={{ fontSize: 25, color: "#109cf1" }} />
              </div>
              <a href={res.file}>
                {res.file.split("/")[res.file.split("/").length - 1]}
              </a>
            </div>
          ) : (
            ""
          )}
          {res.message != "undefined" ? res.message : ""}
        </div>
      </div>
    );
  }
  function Messagemy(res) {
    return (
      <div className="chat-message-right pb-4">
        <div>
          {!res.user.avatar ? (
            <Avatar sx={{ width: 32, height: 32 }}>
              {res.user.username.charAt(0)}
            </Avatar>
          ) : (
            <img src={res.user.avatar} className="av" width="40" height="40" />
          )}
          <div className="text-muted small text-nowrap mt-2">
            {dateFormat(res.created_at, "HH:MM")}
            <br />

            {dateFormat(res.created_at, "dd.mm.yyyy")}
          </div>
        </div>
        <div
          className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3"
          style={{ maxWidth: "50%" }}
        >
          <div className="font-weight-bold mb-1">{t("You")}</div>

          {res?.file ? (
            <div className="row p-2">
              <div className="pr-2">
                <FileOutlined style={{ fontSize: 25, color: "#109cf1" }} />
              </div>
              <a href={res.file}>
                {res.file.split("/")[res.file.split("/").length - 1]}
              </a>
            </div>
          ) : (
            ""
          )}
          {res.message != "undefined" ? res.message : ""}
        </div>
      </div>
    );
  }
  const fileRefs = React.createRef();
  const [file, setFile] = useState();
  const openFile = () => {
    fileRefs.current.click();
  };
  function handleChange(event) {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      setShowse(true);
    } else setShowse(false);
  }
  const bottomRef = useRef(null);
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [datas]);
  const handleCancele = () => {
    setShowse(false);
    setFile();
  };
  return (
    <main className="content overflow-hidden">
      <ModalChat show={showse} handleCancel={handleCancele} file={file} />
      <input
        type="file"
        id="file"
        ref={fileRefs}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <div className=" p-0 mr-0 ml-0">
        <div className="card">
          <div>
            <div className="border-bottom d-none d-lg-block">
              <div className="d-flex align-items-center">
                <div className="app-tt flex-grow-1">
                  <h3 className="app-heading">
                    {t("Application correspondence")}
                  </h3>
                </div>
              </div>
            </div>
            <div className="position-relative list-deadline" id="datas">
              <div className="chat-messages p-4 list-deadline">
                {datas?.map((option, i) => (
                  <div key={i}>
                    {option.user.id == userid ? (
                      <div key={i}> {Messagemy(option)}</div>
                    ) : (
                      <div key={i}>{Messageany(option)}</div>
                    )}
                    {dateFormat(datas[i]?.created_at, "dd") !==
                    dateFormat(datas[i + 1]?.created_at, "dd") ? (
                      <div className="date-center">
                        {dateFormat(datas[i + 1]?.created_at, "dd.mm.yyyy")}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
                <div ref={bottomRef} />
              </div>
            </div>
            <div>
              <div className="chat-input border-top">
                <div className="icon-chat" onClick={openFile}>
                  <PaperClipOutlined style={{ fontSize: 25 }} />
                </div>
                <Form
                  ref={formRef}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item style={{ width: "100%" }} name="message">
                    <Input
                      className="mess-inp"
                      autoFocus
                      type={"text"}
                      bordered={false}
                      size="large"
                      placeholder={t("Write a message...")}
                    />
                  </Form.Item>
                  <Button style={{ outline: "none" }} type="submit">
                    <SendOutlined style={{ fontSize: 25, color: "#109cf1" }} />
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default withTranslation()(Chat);
