import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import "./Reestr/style.scss";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { Alert, Empty, Pagination, Spin } from "antd";
import { Helmet } from "react-helmet-async";
import { Input } from "reactstrap";
import { FilterOutlined } from "@ant-design/icons";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

function CheckCertificate({ t }) {
  const [error, seterror] = useState(false);
  const lan = useSelector((state) => state.langReducer.value);
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(false);
    axios
      .get(url.url + `/certificate/pdf/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
        setLoad(true);
      })
      .catch((err) => {
        if (err.response.status == 404) {
          seterror(true);
          setLoad(true);
        }
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        toast.error(err.response.message);
      });
  }, [lan]);

  return (
    <div className="res-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Certificate_information")}</title>
        <meta name="description" content={t("herotitle")} />
      </Helmet>
      {!load ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 500,
            backgroundColor: "white",
            borderRadius: 12,
          }}
        >
          <Spin tip={t("Loading...")}>
            <Empty description={t("No data")} />
          </Spin>
        </div>
      ) : (
        <>
          {error ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Alert
                className="mb-3 w-50  pr-5 "
                message={t("Certificate_not_found")}
                type="error"
                showIcon
              />
            </div>
          ) : (
            <>
              <h2 className="landing-reg__title">
                {" "}
                №{data?.number} {t("Certificate_information")}
              </h2>
              <div className="res-wrapper-inner landing-container">
                <div className="wrrr" size="small" arial-label="bought">
                  <p
                    className="rester-name "
                    style={{ fontSize: 24, color: "#144789" }}
                  >
                    {t("Applicant_Information")}{" "}
                  </p>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Applicant")} </p>
                    <p className="rester-title">
                      {data ? data.legal_entity?.name : ""}
                    </p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Name")} </p>
                    <p className="rester-title">
                      {data ? data.legal_entity?.boss_name : ""}
                    </p>
                  </div>{" "}
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Email")} </p>
                    <p className="rester-title">
                      {data ? data.legal_entity?.email : ""}
                    </p>
                  </div>{" "}
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Address")} </p>
                    <p className="rester-title">
                      {data ? data.legal_entity?.address : ""}
                    </p>
                  </div>{" "}
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Phone")} </p>
                    <p className="rester-title">
                      {data ? data.legal_entity?.phone : ""}
                    </p>
                  </div>
                  <div className="head-name-rester"></div>
                  <p
                    className="rester-name "
                    style={{ fontSize: 24, color: "#144789" }}
                  >
                    {t("Sertificat")}{" "}
                  </p>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Registration number")} </p>
                    <p className="rester-title"> {data ? data.number : ""}</p>
                  </div>
                  <div className="rester__name-row">
                    <p className="rester-name">{t("Date range")} </p>
                    <p className="rester-title">
                      {" "}
                      {dateFormat(data?.start_date, "dd.mm.yyyy")} -{" "}
                      {dateFormat(data?.end_date, "dd.mm.yyyy")}
                    </p>
                  </div>
                </div>
                <div className="head-name-rester"></div>
                <p
                  className="rester-name "
                  style={{ fontSize: 24, color: "#144789" }}
                >
                  {t("Drug Information")}{" "}
                </p>
                <div className="rester__name-row">
                  <p className="rester-name">{t("Drug name")} </p>
                  <p className="rester-title"> {data ? data.drug.name : ""}</p>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("Trade name")} </p>
                  <p className="rester-title">
                    {" "}
                    {data ? data.drug.trade_name : ""}
                  </p>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("Drug form")} </p>
                  <p className="rester-title">
                    {" "}
                    {data ? data.drug.drug_figure?.name : ""}
                  </p>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("Content")} </p>
                  <p className="rester-title">
                    {" "}
                    {data ? data.drug.structure : ""}
                  </p>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("Manufacturer country")} </p>
                  <p className="rester-title">
                    {data ? data.drug.country.name : ""}
                  </p>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("Manufacturer")} </p>
                  <p className="rester-title">
                    {data ? data.drug.manufacturer.name : ""}
                  </p>
                </div>{" "}
                <div className="rester__name-row">
                  <p className="rester-name">{t("Preparer")} </p>
                  <p className="rester-title">
                    {" "}
                    {data ? data.drug.preparer.name : ""}
                  </p>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("Applicat")} </p>
                  <p className="rester-title"> {data ? data.drug.usage : ""}</p>
                </div>
                <div className="head-name-rester"></div>
                <p
                  className="rester-name "
                  style={{ fontSize: 24, color: "#144789" }}
                >
                  {t("Instruction")}{" "}
                </p>
                <div className="rester__name-row">
                  <p className="rester-name">{t("general")} </p>
                  <div
                    className="rester-title"
                    dangerouslySetInnerHTML={{
                      __html: data?.drug.instruction.general
                        ? data?.drug.instruction.general[lan]
                        : "",
                    }}
                  ></div>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("pharmacological")} </p>
                  <div
                    className="rester-title"
                    dangerouslySetInnerHTML={{
                      __html: data?.drug.instruction.pharmacological
                        ? data?.drug.instruction.pharmacological[lan]
                        : "",
                    }}
                  ></div>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("prevention")} </p>
                  <div
                    className="rester-title"
                    dangerouslySetInnerHTML={{
                      __html: data?.drug.instruction.prevention
                        ? data?.drug.instruction.prevention[lan]
                        : "",
                    }}
                  ></div>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("protest")} </p>
                  <div
                    className="rester-title"
                    dangerouslySetInnerHTML={{
                      __html: data?.drug.instruction.protest
                        ? data?.drug.instruction.protest[lan]
                        : "",
                    }}
                  ></div>
                </div>
                <div className="rester__name-row">
                  <p className="rester-name">{t("usage")} </p>
                  <div
                    className="rester-title"
                    dangerouslySetInnerHTML={{
                      __html: data?.drug.instruction.usage
                        ? data?.drug.instruction.usage[lan]
                        : "",
                    }}
                  ></div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default withTranslation()(CheckCertificate);
